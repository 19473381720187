export default {
  auth: {
    signIn: '/auth/login',
    forgotPassword: '/auth/forgotPassword',
    verifyToken: '/auth/verifyToken',
    setNewPassword: '/auth/setNewPassword',
    logout: '/auth/signout',
  },
  applicationSettings: {
    formUpload: '/admin-service/uploadform',
    settings: '/admin-service/api/v1/settings',
    resources: '/resource-service/resources',
    branding: '/resource-service/branding',
    formList: '/admin-service/medic/_design/medic-client/_view/forms?include_docs=true',
    formData: '/admin-service/document/medic',
    formHTML: '/admin-service/formId/preview',
    languages: `/admin-service/medic/_design/medic-client/_view/doc_by_type`,
    adminService: '/admin-service',
    bulkDocks: '/document-service-up/medic/_bulk_docs',
    imageFromBranding: '/resource-service/images/branding',
    imageFromResources: '/resource-service/images/resources',
  },
  location: {
    getDeviceIds: '/location-service/deviceids',
    getLocation: '/location-service/users',
    getAllForms: '/admin-service/medic/_design/medic-client/_view/forms?include_docs=true',
  },
  userSettings: {
    settings:
      '/admin-service/medic/_design/medic-client/_view/doc_by_type?include_docs=true&key=%5B%22user-settings%22%5D',
    users: '/admin-service/api/v1/users',
  },
  people: {
    branches: '/admin-service/medic/_design/medic-admin-people/_view/branch',
    docDetailsByParentId: '/admin-service/medic/_design/medic-client/_view/contacts_by_parent',
    document: '/admin-service/document/medic/',
    reportDetails: '/admin-service/medic/_design/medic-admin-people/_view/reports_by_person',
    reportDetailsByContact: '/admin-service/medic/_design/medic-admin-people/_view/reports_by_contact',
    individualUsersByBranch: '/admin-service/medic/_design/medic_new-enduser/_view/individualusers_by_branch',
    contactsByType: '/admin-service/medic/_design/medic-admin-people/_view/admin_contact_deatils?keys=',
    images: '/resource-service/images/resources/',
    resources: '/resource-service/resources',
    getFormModelValue: '/admin-service/document/medic',
    saveFormValue: '/admin-service/saveformdetails',
    updateFormValue: '/admin-service/updateformdetails',
  },
  adminService: '/admin-service',
  bulkDocks: '/document-service-up/medic/_bulk_docs',
  reports: {
    changesPrefix: '/document-service-down/medic/_changes?style=all_docs&since=',
    changesSuffix: '&limit=500',
    byDate: '/admin-service/medic/_design/medic-client/_view/reports_by_date?limit=50&descending=true&skip=',
    getFormImage: '/resource-service/images/resources/',
    byForm: '/admin-service/medic/_design/medic-client/_view/reports_by_form?reduce=false&keys=',
    byPlace: '/admin-service/medic/_design/medic-client/_view/reports_by_place?keys=',
    placeList: '/admin-service//medic/_design/medic-client/_view/contacts_by_type?include_docs=true&key=',
    searchReportStart: '/admin-service/medic/_design/medic-client/_view/reports_by_freetext?startkey=',
    searchReportEnd: '&endkey=',
    getDateStart: '/admin-service/medic/_design/medic-client/_view/reports_by_date?startkey=',
    getDateEnd: '&endkey=',
    getPlaceList: '/admin-service/medic/_design/medic-client/_view/contacts_by_type?include_docs=true&key=',
    getViewReportDetail: '/admin-service/document/medic/',
    reviewReport: '/document-service-up/medic/_bulk_docs',
    getAllDocByReportId: '/document-service-down/medic/_all_docs?conflicts=true&include_docs=true',
    getResources: `/resource-service/resources`,
    getForms: `/admin-service/medic/_design/medic-client/_view/forms?include_docs=true`,
    getFormDetails: '/admin-service/document/medic/form:',
    getFormImageIcon: '/resource-service/images/resources/',
    getFormXmlDetails: '/admin-service/document/medic/',
    Xml: '/xml',
  },
  jsonRuleEditor: {
    saveJsonRule: '/admin-service/saveform',
    getJsonRule: '/admin-service/document/medic/',
    updateJsonRule: '/admin-service/saveform?action=replace'
  },
};
