import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent implements OnInit, AfterViewInit {
  @ViewChild('baseModalContent') modalTemplate: any;
  @Input() content: any;
  @Input() title: any;
  @Input() modalStyle: any;
  @Input() isAssociatedContact?: any;
  @Input() modalOptions: any;
  @Input() isFormValid = true;
  @Output() modalClose = new EventEmitter();
  @Output() formSubmit = new EventEmitter();

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.modalService.open(this.modalTemplate, this.modalOptions).result.then(
      (result) => {
        this.formSubmit.emit();
      },
      (reason) => {
        this.modalClose.emit();
      }
    );
  }
}
