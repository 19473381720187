import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AppService } from 'src/app/services/app.service';
import appRouter from '../../constants/api-route-constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm!: FormGroup;

  constructor(private router: Router, private fb: FormBuilder, private appService: AppService) {}

  ngOnInit(): void {
    this.initForgotPasswordForm();
  }

  routeToResetLinkPage(): void {
    this.appService.forgotPassword(this.forgotPasswordForm.value.email).subscribe(() => {
      this.router.navigate([appRouter.auth.resetLink, this.forgotPasswordForm.value.email]);
    });
  }

  backToLogin(): void {
    this.router.navigate([appRouter.auth.login]);
  }

  initForgotPasswordForm(): void {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
}
