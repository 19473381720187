import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { BaseServicesService } from '../services/base-services/base-services.service';
import API_CONSTANTS from './../constants/api-constants';

interface ISignIn {
  user: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppService extends BaseServicesService {
  public buttonValue: any;
  public buttonUpdated = new BehaviorSubject<any>('');

  public formUploadValue: any;
  public uploadUpdated = new BehaviorSubject<any>('');

  public isColorValue = false;
  public isColorUpdated = new Subject<any>();

  setButtonValue(value: any): void {
    this.buttonValue = value;
    this.buttonUpdated.next(this.buttonValue);
  }

  setUploadForm(value: any): void {
    this.formUploadValue = value;
    this.uploadUpdated.next(this.formUploadValue);
  }

  webColorsChange(isValue: boolean): void {
    this.isColorValue = isValue;
    this.isColorUpdated.next(this.isColorValue);
  }

  uploadXlsForm(file: File, iconName: string): Observable<any> {
    const fileForm = new FormData();
    fileForm.append('File', file);
    return this.post(`${API_CONSTANTS.applicationSettings.formUpload}/${iconName}`, fileForm);
  }

  postSignIn(data: ISignIn): Observable<any> {
    return this.post(API_CONSTANTS.auth.signIn, data);
  }

  logout(): Observable<any> {
    return this.delete(API_CONSTANTS.auth.logout);
  }

  forgotPassword(email: string): Observable<any> {
    return this.post(API_CONSTANTS.auth.forgotPassword, { email, ui: 'web' });
  }

  verifyToken(token: any): Observable<any> {
    return this.get(`${API_CONSTANTS.auth.verifyToken}?token=${token}`);
  }

  setNewPassword(data: any): Observable<any> {
    return this.post(API_CONSTANTS.auth.setNewPassword, data);
  }

  refreshToken(): Observable<any> {
    return this.get('/auth/renew');
  }
}
