import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BaseServicesService } from '../base-services/base-services.service';
import API_CONSTANTS from '../../constants/api-constants';

@Injectable({
  providedIn: 'root',
})
export class PeopleService extends BaseServicesService {
  public branch: any;
  public branchUpdated = new BehaviorSubject<any>('');
  public area: any;
  public areaUpdated = new BehaviorSubject<any>('');
  public family: any;
  public familyUpdated = new BehaviorSubject<any>('');
  private branchesList = new BehaviorSubject<any>([]);

  public setBranchValue(data: any): void {
    this.branch = data;
    this.branchUpdated.next(data);
  }

  public setAreaValue(area: any) {
    this.area = area;
    this.areaUpdated.next(area);
  }

  public setFamilyValue(data: any): void {
    this.family = data;
    this.familyUpdated.next(data);
  }

  getContactsByType(branchIds: any): Observable<any> {
    return this.get(`${API_CONSTANTS.people.contactsByType}${branchIds}`);
  }

  public getReportsByPersonId(startKey: string, endKey: string): Observable<any> {
    return this.get(`${API_CONSTANTS.people.reportDetails}?include_docs=true&startkey=${startKey}&endkey=${endKey}`);
  }

  public getReportsByContactId(startKey: string, endKey: string): Observable<any> {
    return this.get(
      `${API_CONSTANTS.people.reportDetailsByContact}?include_docs=true&startkey=${startKey}&endkey=${endKey}`
    );
  }

  public getDocDetailById(id: string | null): Observable<any> {
    return this.get(`${API_CONSTANTS.people.document}/${id}`);
  }

  public getBranches(): void {
    this.get(API_CONSTANTS.people.branches).subscribe(
      (res) => {
        this.branchesList.next(res.rows);
      },
      (error) => {}
    );
  }

  public branchesListObs(): Observable<any> {
    return this.branchesList.asObservable();
  }
  /**
   *
   * @param formId Id for which we need Form model value
   * @returns Form model value to pre-populate
   */
  getFormModelXml(formId: string): Observable<any> {
    const opts = {
      responseType: 'text',
    };
    return this.getXmlResponse(`${API_CONSTANTS.people.getFormModelValue}/${formId}/xml`, opts);
  }

  saveBranchFormData(formValue: any): Observable<any> {
    return this.post(API_CONSTANTS.people.saveFormValue, formValue);
  }

  updateBranchFormData(branchId: string, formValue: any): Observable<any> {
    return this.post(`${API_CONSTANTS.people.updateFormValue}/${branchId}`, formValue);
  }

  public getDocDetailsByParentId(startKey: string, endKey: string): Observable<any> {
    return this.get(
      `${API_CONSTANTS.people.docDetailsByParentId}?include_docs=true&startkey=${startKey}&endkey=${endKey}`
    );
  }
  public getResources(): Observable<any> {
    return this.get(API_CONSTANTS.people.resources);
  }
  public getIcon(iconName: string): Observable<any> {
    return this.get(`${API_CONSTANTS.people.images}/${iconName}`);
  }
  public getIndividualUsersByBranch(startKey: string, endKey: string, skip: number): Observable<any> {
    return this.get(
      `${API_CONSTANTS.people.individualUsersByBranch}?include_docs=true&startkey=${startKey}&endkey=${endKey}&limit=10&skip=${skip}`
    );
  }
}
