import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { base64Prefix } from 'src/app/constants/constants';
import { BaseServicesService } from 'src/app/services/base-services/base-services.service';
import { PeopleService } from 'src/app/services/people/people.service';

import appRouter from '../../../../constants/api-route-constants';

@Component({
  selector: 'app-supervisor-detail',
  templateUrl: './supervisor-detail.component.html',
  styleUrls: ['./supervisor-detail.component.scss'],
})
export class SupervisorDetailComponent implements OnInit {
  branch: any = {};
  area: any = { name: 'Area' };
  family: any = {
    contact: { name: 'Family' },
  };
  reports: any = [];
  supervisor: any = {};
  resources: any = {};
  form: any = {};
  icon: any;
  personIcon: any;
  empty = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private peopleService: PeopleService,
    private baseService: BaseServicesService
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.updateParentValues();
    this.getResources();
    this.getSupervisorDetails(id);
  }

  /**
   * <p>
   *  Route back to branch detail.
   * </p>
   *
   * @param branch
   */
  routeBackToBranch(branch: string): void {
    this.peopleService.setBranchValue(branch);
    this.router.navigate([appRouter.home.peopleMenu.branchDetail]);
  }

  /**
   * <p>
   *   Get Icons from the resources.
   * </p>
   */
  private getResources() {
    this.peopleService.getResources().subscribe((res: any) => {
      this.resources = res.resources;
      this.peopleService.getIcon(this.resources.person).subscribe((image: any) => {
        if (image) {
          this.personIcon = base64Prefix + image.base64;
        }
      });
    });
  }

  /**
   * <p>
   *   Get the updated parent values like branch, area and
   * family for the supervisor.
   * </p?
   */
  private updateParentValues() {
    this.peopleService.branchUpdated.subscribe((data) => {
      this.branch = data;
    });

    this.peopleService.areaUpdated.subscribe((data) => {
      this.area = data;
    });

    this.peopleService.familyUpdated.subscribe((data) => {
      this.family = data;
    });
  }

  /**
   * <p>
   *  Get supervisor details by supervisor id.
   * </p>
   *
   * @param id
   */
  private getSupervisorDetails(id: string | null) {
    this.peopleService.getDocDetailById(id).subscribe((res) => {
      this.supervisor = res;
      if (res.name) {
        this.supervisor.person_name = res.name;
      } else if (res.person) {
        if (res.person.name) {
          this.supervisor.person_name = res.person.name;
        }
      }
      if (res.date_of_birth) {
        this.supervisor.person_age = this.calculateAgeFromDOB(new Date(res.date_of_birth));
      } else if (res.age) {
        this.supervisor.person_age = res.age;
      } else if (res.person) {
        if (res.person.age) {
          this.supervisor.person_age = res.person.age;
        }
      }
    });
    const startKey = '["' + id + '"]';
    const endKey = '["' + id + '", {}]';

    this.peopleService
      .getReportsByPersonId(encodeURIComponent(startKey), encodeURIComponent(endKey))
      .subscribe((res) => {
        res.rows.forEach((row: any) => {
          if (row) {
            this.peopleService.getDocDetailById(row.doc.form).subscribe((data) => {
              row.doc.formname = data.title;
              this.peopleService.getIcon(this.resources[data.icon]).subscribe((image: any) => {
                if (image) {
                  row.doc.icon = base64Prefix + image.base64;
                }
              });
              row.doc.days = this.timeskip(new Date(row.doc.reported_date));
              this.reports.push(row.doc);
            });
          }
        });
      });

    if (!this.family && !this.area && this.reports.length === 0) {
      const branchStartKey = '["' + this.branch._id + '"]';
      const branchEndKey = '["' + this.branch._id + '", {}]';
      this.peopleService
        .getReportsByContactId(encodeURIComponent(branchStartKey), encodeURIComponent(branchEndKey))
        .subscribe((res) => {
          res.rows.forEach((row: any) => {
            if (row.doc.form.startsWith('form:')) {
              this.peopleService.getDocDetailById(row.doc.form).subscribe((data) => {
                row.doc.formname = data.title;
                this.peopleService.getIcon(this.resources[data.icon]).subscribe((image: any) => {
                  if (image) {
                    row.doc.icon = base64Prefix + image.base64;
                  }
                });
              });
            } else {
              this.peopleService.getIcon('mute.png').subscribe((mute: any) => {
                if (mute) {
                  row.doc.icon = base64Prefix + mute.base64;
                }
              });
              if (row.doc.form === 'muting-approve-request') {
                row.doc.formname = 'Muting Request Approval';
              } else {
                row.doc.formname = row.doc.form;
              }
            }
            row.doc.days = this.timeskip(new Date(row.doc.reported_date));
            this.reports.push(row.doc);
          });
        });
    }
  }

  /**
   * <p>
   *  Method used to get the day differences given the date.
   * </p>
   *
   * @param date
   * @returns number of days or years or months ago value.
   */
  private timeskip(date: any) {
    const currentDate: any = new Date();
    const diff: any = currentDate - date;
    const seconds = Math.floor(diff / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + ' years ago';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + ' months ago';
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + ' days ago';
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' hours ago';
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' minutes ago';
    }
    return Math.floor(seconds) + ' seconds ago';
  }

  /**
   * <p>
   *   Calculate Age from Date of birth
   * </p>
   *
   * @param dob
   * @returns age
   */
  private calculateAgeFromDOB(dob: Date): any {
    const monthDiff = Date.now() - dob.getTime();
    const ageDt = new Date(monthDiff);
    const year = ageDt.getUTCFullYear();
    return Math.abs(year - 1970);
  }
}
