import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { LoaderService } from 'src/app/services/loader/loader.service';

import { LocationService } from '../../services/location/location.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {
  selectedValue = '';
  selectedFormValue: any;
  toggleChecked = true;
  parentArray: any;
  subArray: any;
  isChecked = false;
  latlng: any;
  formName: Array<any> = [];
  flagArray: any;
  startDate = '';
  deviceId = '';
  endDate = '';
  selectedButton = null;
  searchArray = [
    {
      name: '',
      id: '',
    },
  ];
  keyword = 'name';
  polyLineColors: string[] = [];
  buttons = [{ name: 'Now' }, { name: 'Today' }, { name: 'This Week' }, { name: 'This Month' }];

  constructor(private locationService: LocationService, private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.getAllFormsList();
    this.showRouteMap();
    this.getRandomColors();
  }

  /**
   * To get the list of forms in select box.
   */
  getAllFormsList(): void {
    this.locationService.getAllForms().subscribe((res) => {
      this.formName = res.rows;
      this.formName.push({ doc: { title: 'All forms', internalId: '' } });
    });
  }

  /**
   * To get the Random colors for polyline.
   */
  getRandomColors(): void {
    this.polyLineColors = [];
    for (let i = 0; i < 31; i++) {
      this.polyLineColors.push('#' + Math.floor(Math.random() * 16777215).toString(16));
    }
  }

  /**
   * To get the Route map visualization.
   */
  showRouteMap(): void {
    const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      zoom: this.latlng ? 10 : 4,
      center: this.latlng
        ? { lat: this.latlng?.locations[0]?.latitude, lng: this.latlng?.locations[0]?.longitude }
        : { lat: 9.3465091438791, lng: 20.660570482930865 },
      mapTypeId: 'terrain',
    });
    if (this.parentArray) {
      for (let i = 0; i < this.parentArray.length; i++) {
        const markerA = new google.maps.Marker({
          map,
          position: new google.maps.LatLng(this.parentArray[i][0]?.latitude, this.parentArray[i][0]?.longitude),
          label: 'A',
        });
        moment(Date.now()).subtract({days:3, hours:5}); 
        const pointAToolTip = `<div class="font-weight-500">
        Accuracy: ${this.parentArray[i][0]?.accuracy}m </br>
        Start: ${moment(this.parentArray[i][0]?.createdAt).subtract({hours:5, minute: 30}).format('llll')}</div>`;
        const infoWindowA = new google.maps.InfoWindow({
          content: pointAToolTip,
        });
        markerA.addListener('click', () => {
          infoWindowA.open(map, markerA);
        });
        if (this.parentArray[i].length > 1) {
          // var geocoder = new google.maps.Geocoder();
          // var latlng1 = new google.maps.LatLng(this.parentArray[i][0].latitude, this.parentArray[i][0].longitude);
          // geocoder.geocode({ location: latlng1 }, function (results, status) {
          //   if (status == google.maps.GeocoderStatus.OK) {
          //   }
          // });
          const markerB = new google.maps.Marker({
            map,
            position: new google.maps.LatLng(
              this.parentArray[i][this.parentArray[i].length - 1]?.latitude,
              this.parentArray[i][this.parentArray[i].length - 1]?.longitude
            ),
            label: 'B',
          });
          const pointBToolTip = `<div class="font-weight-500">
          Accuracy: ${this.parentArray[i][this.parentArray[i].length - 1].accuracy}m </br>
          End: ${moment(this.parentArray[i][this.parentArray[i].length - 1].createdAt).subtract({hours:5, minute: 30}).format('llll')}</div>`;
          const infoWindowB = new google.maps.InfoWindow({
            content: pointBToolTip,
          });
          markerB.addListener('click', () => {
            infoWindowB.open(map, markerB);
          });
        } else {
          // new google.maps.Marker({
          //   map: map,
          //   position: new google.maps.LatLng(
          //     this.parentArray[i][0].latitude + (Math.random() - 0.5) / 1500,
          //     this.parentArray[i][0].longitude + (Math.random() - 0.5) / 1500
          //   ),
          //   label: 'B',
          // });
        }
      }
      if (this.flagArray && this.flagArray.length > 0) {
        const image = 'assets/images/google-map-flag.svg';
        for (let i = 0; i < this.flagArray.length; i++) {
          const flagMarker = new google.maps.Marker({
            map,
            position: new google.maps.LatLng(this.flagArray[i].latitude, this.flagArray[i].longitude),
            icon: image,
          });
          console.log('flag', this.flagArray);
          const flagToolTip = `<div class="font-weight-500">
          Form: ${this.flagArray[i].displayName ? this.flagArray[i].displayName : 'Not available'}</br>
          Accuracy: ${this.flagArray[i]?.accuracy}m </br>
          Date: ${moment(this.flagArray[i]?.createdAt).subtract({hours:5, minute: 30}).format('llll')}
          </div>`;
          const infoWindowC = new google.maps.InfoWindow({
            content: flagToolTip,
          });
          flagMarker.addListener('click', () => {
            infoWindowC.open(map, flagMarker);
          });
        }
      }
      for (let i = 0; i < this.parentArray.length; i++) {
        const latLngArray = [];
        for (let j = 0; j < this.parentArray[i].length; j++) {
          latLngArray.push(new google.maps.LatLng(this.parentArray[i][j]?.latitude, this.parentArray[i][j]?.longitude));
        }
        const flightPath = new google.maps.Polyline({
          path: latLngArray,
          geodesic: true,
          strokeColor: this.polyLineColors[i],
          strokeOpacity: 1.0,
          strokeWeight: 6,
        });
        flightPath.setMap(map);
      }
    }
  }

  /**
   * To get the Heat map visualization.
   */
  showHeatMap() {
    const map2 = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      zoom: 13,
      center: this.latlng
        ? { lat: this.latlng?.locations[0]?.latitude, lng: this.latlng?.locations[0]?.longitude }
        : { lat: 9.3465091438791, lng: 20.660570482930865 },
      mapTypeId: 'satellite',
    });
    const heatMapPoints: google.maps.LatLng[] = [];
    this.latlng?.locations?.map((data: any) => {
      heatMapPoints.push(new google.maps.LatLng(data.latitude, data.longitude));
    });
    new google.maps.visualization.HeatmapLayer({
      data: heatMapPoints,
      map: map2,
    });
  }

  /**
   * To get the start and end date based on the button clicked.
   */
  onButtonClick(i: any, button: any): void {
    this.selectedButton = i;
    this.endDate = moment().format('yyyy-MM-DD HH:mm:ss.SSS').replace(' ', 'T') + 'Z';
    if (button.name === 'Now') {
      this.startDate = moment().subtract(15, 'm').format('yyyy-MM-DD HH:mm:ss.SSS').replace(' ', 'T') + 'Z';
    }
    if (button.name === 'Today') {
      this.startDate = moment().startOf('day').format('yyyy-MM-DD HH:mm:ss.SSS').replace(' ', 'T') + 'Z';
    }
    if (button.name === 'This Week') {
      this.startDate = moment().subtract(7, 'd').format('yyyy-MM-DD HH:mm:ss.SSS').replace(' ', 'T') + 'Z';
    }
    if (button.name === 'This Month') {
      this.startDate = moment().subtract(30, 'd').format('yyyy-MM-DD HH:mm:ss.SSS').replace(' ', 'T') + 'Z';
    }
    if (this.deviceId) {
      this.getLocationData();
    }
  }

  /**
   * To get the location and filtration to show on route map.
   */
  getLocationData(): void {
    this.flagArray = [];
    this.locationService.getLocation(this.deviceId, this.startDate, this.endDate).subscribe((data) => {
      this.latlng = data;
      if (this.selectedFormValue) {
        this.selectedForm(this.selectedFormValue);
      } else {
        this.latlng.locations.map((val: any) => {
          this.flagArray.push(val);
        });
      }
      this.parentArray = [];
      this.subArray = [];
      for (let i = 0; i < this.latlng.locations.length - 1; i++) {
        this.subArray.push(this.latlng.locations[i]);
        if (
          moment(this.latlng.locations[i].createdAt).format('yyyy-MM-DD') !==
          moment(this.latlng.locations[i + 1].createdAt).format('yyyy-MM-DD')
        ) {
          this.parentArray.push(this.subArray);
          this.subArray = [];
        }
      }
      this.subArray.push(this.latlng.locations[this.latlng.locations.length - 1]);
      this.parentArray.push(this.subArray);
      if (this.toggleChecked) {
        this.showRouteMap();
      } else {
        this.showHeatMap();
      }
    });
  }

  /**
   * switch toggle to show heat map or route map.
   */
  onSwitchValue(): void {
    this.toggleChecked = !this.toggleChecked;
    if (this.toggleChecked) {
      this.showRouteMap();
    } else {
      this.showHeatMap();
    }
  }

  selectedForm(selectedValue: any): void {
    this.selectedFormValue = selectedValue;
    this.flagArray = [];
    if (this.selectedFormValue && this.latlng) {
      this.latlng.locations.map((data: any) => {
        if (this.selectedFormValue.doc.internalId === data.formName || this.selectedFormValue.id === data.formName) {
          this.flagArray.push(data);
        }
        if (this.selectedFormValue.doc.internalId === '') {
          this.flagArray.push(data);
        }
      });
      this.showRouteMap();
    }
  }

  /**
   * To get the device id of selected user from autocomplete input.
   */
  selectEvent(item: any) {
    this.deviceId = item.id;
    if (this.startDate && this.endDate && this.deviceId) {
      this.getLocationData();
    }
  }

  inputCleared(): void {
    this.deviceId = '';
  }

  onChangeSearch() {
    this.locationService.getDeviceId().subscribe((data) => {
      this.searchArray = [];
      data.map((val: any) => {
        if (val.userName !== null) {
          this.searchArray.push({ name: val.userName, id: val.device_id });
        }
      });
    });
    this.loaderService.hideLoading();
  }

  onFocused(e: any) {
    // do something when input is focused
  }
}
