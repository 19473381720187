import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { FormPreviewComponent } from '../shared/form-preview/form-preview.component';
import { UtilService } from './util/util.service';

export class FormPreview {
  private uiSettings: any;

  constructor(private ngbModalService: NgbModal, private utilityService: UtilService) {}

  openPreviewModal(formData: { form: string; model: string; formValue: string }, name?: string): Observable<any> {
    const modalRef = this.ngbModalService.open(FormPreviewComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'enketo-form-preview-container',
      scrollable: true,
      size: 'xl',
    });
    modalRef.componentInstance.formHTML = this.utilityService.escapeBackSlash(formData.form);
    modalRef.componentInstance.formXML = this.utilityService.escapeBackSlash(formData.model);
    modalRef.componentInstance.formModel = formData.formValue;
    modalRef.componentInstance.uiSettings = this.uiSettings;
    modalRef.componentInstance.header = name;

    return modalRef.componentInstance.formValidate;
  }

  setUiSettings(uiSettings: any) {
    this.uiSettings = uiSettings;
  }
}
