<div class="modal-header border-bottom-0 py-3 px-3">
  <span class="font-size-20 font-weight-500">{{header? header: 'Preview'}}</span>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true"><img src="assets/images/cross.svg" alt="close" width="15" /></span>
  </button>
</div>
<div class="modal-body">
  <div class="paper" #formContainer>
    <div [outerHTML]="safeHTML"></div>
    <section class="form-footer">
      <div class="form-footer__content">
        <div class="form-footer__content__main-controls">
          <button class="previous-page btn disabled" type="button" tabindex="2" [ngStyle]="{
              'border-color': uiSettings?.mobile?.application_colors.primary,
              color: uiSettings?.mobile?.application_colors.primary
            }">
            <i class="icon icon-arrow-left"></i><span data-i18n="form.pages.back">Back</span>
          </button>
          <button class="btn text-white" id="submit-form" type="button" (click)="validate()"
            [ngStyle]="{ 'background-color': uiSettings?.mobile?.application_colors.primary }">
            <i class="icon icon-check"> </i>
            <span data-i18n="formfooter.submit.btn">Submit</span>
          </button>
          <button class="btn next-page text-white" type="button" tabindex="2"
            [ngStyle]="{ 'background-color': uiSettings?.mobile?.application_colors.primary }">
            <i class="icon icon-arrow-right"></i><span data-i18n="form.pages.next">Next</span>
          </button>
        </div>
      </div>
    </section>
  </div>
</div>
<div class="modal-footer p-2">
  <button class="btn btn-outline-secondary" (click)="activeModal.dismiss()">Close</button>
</div>