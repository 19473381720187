import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationSettingsService } from '../services/application-settings/application-settings.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(private activateRoute: ActivatedRoute, private applicationSettingsService: ApplicationSettingsService) {}

  ngOnInit(): void {
    this.activateRoute.data.subscribe((res) => {
      this.applicationSettingsService.setApplicationSettings(res.data);
    });
  }
}
