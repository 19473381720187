import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() totalRecords = 0;
  @Input() limit = 0;
  @Output() pageChangeEmitter: EventEmitter<number> = new EventEmitter();
  startPage: number;
  currentPage: number;
  pages: number[];
  totalPage: number;
  range: number;
  start: number;
  offset: number;
  showEllipses: boolean;

  constructor() {
    this.startPage = 1;
    this.currentPage = 1;
    this.pages = [];
    this.totalPage = 0;
    this.range = 2;
    this.start = 0;
    this.offset = 0;
    this.showEllipses = false;
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.setPageCount();
    this.showEllipses = this.totalPage > this.range + 1;
    this.setPages();
    this.setPageProp();
    this.pageChangeEmitter.emit(1);
  }

  /**
   * To act on page number click
   */
  onClickPage(pageNumber: number): void {
    if (pageNumber >= 1 && pageNumber <= this.totalPage) {
      this.currentPage = pageNumber;
      this.setPages();
      this.setPageProp();
      this.pageChangeEmitter.emit(this.currentPage);
    }
  }

  /**
   * To set the page count
   */
  private setPageCount(): void {
    this.totalPage = 0;
    if (this.totalRecords > 0 && this.limit > 0) {
      const pageCount = this.totalRecords / this.limit;
      const roundedPageCount = Math.floor(pageCount);
      this.totalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
    }
  }

  /**
   * To set the pages
   */
  private setPages(): void {
    if (this.totalPage <= 0) {
      this.pages = [];
      return;
    }
    if (this.showEllipses) {
      this.setPaginationPages();
      return;
    }
    this.setPagesWithIndex(1, this.totalPage);
  }

  /**
   * To set the pages with index and range
   *
   * @param index - is the start of page
   * @param pageRange - is the range of page
   */
  private setPagesWithIndex(index: number, pageRange: number): void {
    this.pages = [];
    while (index <= pageRange) {
      this.pages.push(index);
      index++;
    }
  }

  /**
   * To set the pagination pages
   */
  private setPaginationPages(): void {
    let pageRange = 0;
    let index = 1;
    if (1 === this.currentPage) {
      pageRange = this.range + index - 1;
      this.setPagesWithIndex(index, pageRange);
      return;
    }
    index = this.currentPage;
    if (this.currentPage + this.range < this.totalPage + 1) {
      pageRange = this.range + index - 1;
      this.setPagesWithIndex(index, pageRange);
      return;
    }
    index = this.totalPage - this.range + 1;
    pageRange = this.totalPage;
    this.setPagesWithIndex(index, pageRange);
  }

  /**
   * To set the pagination prop i.e start and offset
   */
  private setPageProp(): void {
    this.start = this.currentPage * this.limit - this.limit;
    this.offset = this.start + this.limit;
    if (this.totalRecords < this.offset) {
      this.offset = this.totalRecords;
    }
  }
}
