import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseServicesService } from '../base-services/base-services.service';
import apiConstants from '../../constants/api-constants';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends BaseServicesService {
  getDeviceId(): Observable<any> {
    return this.get(apiConstants.location.getDeviceIds);
  }

  getLocation(deviceId: string, startDate: string, endDate: string): Observable<any> {
    return this.get(
      `${apiConstants.location.getLocation}/${deviceId}/locations?startDate=${startDate}&endDate=${endDate}`
    );
  }

  getAllForms(): Observable<any> {
    return this.get(apiConstants.location.getAllForms);
  }
}
