<div class="text-center">
  <img src="assets/images/message-logo.svg" alt="message-logo" />
</div>
<div
  class="text-center pt-2 pb-2 font-weight-400 reset-title mw-300 mx-auto"
  [innerHTML]="'AUTH.REST_LINK_TITLE' | translate"
></div>
<p class="text-center pt-2" [innerHTML]="'AUTH.RESET_LINK_MESSAGE' | translate"></p>
<div class="d-flex justify-content-between mt-3 link-button">
  <div class="font-size-14 cursor-pointer" (click)="backToLogin()">
    <img src="assets/images/back-arrow.svg" alt="back-arrow" />
    {{ 'AUTH.BACK_TO_LOGIN' | translate }}
  </div>
  <div class="font-size-14 cursor-pointer" (click)="resendResetLink()">
    <img src="assets/images/resend-icon.svg" alt="resend-icon" />
    {{ 'AUTH.RESEND_RESET_LINK' | translate }}
  </div>
</div>
