import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { delay } from 'rxjs/operators';
import { LoaderService } from './services/loader/loader.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loadingMessage = '';
  isLoading = false;

  constructor(private translateService: TranslateService, private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.translateService.setDefaultLang('en');
    this.getLoaderState();
  }

  getLoaderState(): void {
    this.loaderService.isLoading$.pipe(delay(0)).subscribe((loading: boolean) => {
      this.loadingMessage = '';
      this.isLoading = loading;
    });
  }
}
