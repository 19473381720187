import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class BaseServicesService {
  private baseApiUrl: string = environment.baseApiUrl;

  constructor(private http: HttpClient) {}

  getBaseApiUrl() {
    return this.baseApiUrl;
  }
  /**
   * @description GET request
   * @param url string
   */
  get(url: string, paramsObj?: any): Observable<any> {
    const params = this.appendParams(paramsObj);
    return paramsObj
      ? this.http.get(`${this.baseApiUrl}${url}`, { params })
      : this.http.get(`${this.baseApiUrl}${url}`);
  }

  /**
   * @description POST request
   * @param url string
   * @param reqBody any
   */
  post(url: string, reqBody: any): Observable<any> {
    return this.http.post(`${this.baseApiUrl}${url}`, reqBody);
  }

  /**
   * @description PUT request
   * @param url string
   * @param reqBody any
   */
  put(url: string, reqBody?: any, params?: any): Observable<any> {
    return this.http.put(`${this.baseApiUrl}${url}`, reqBody, { params });
  }

  /**
   * @description DELETE request
   * @param url string
   * @param reqBody any
   */
  delete(url: string): Observable<any> {
    return this.http.delete(`${this.baseApiUrl}${url}`);
  }

  /**
   * @description PATCH request
   * @param url string
   * @param reqBody any
   */
  patch(url: string, reqBody?: any, paramsObj?: any): Observable<any> {
    const params = this.appendParams(paramsObj);
    return reqBody
      ? this.http.patch(`${this.baseApiUrl}${url}`, reqBody)
      : this.http.get(`${this.baseApiUrl}${url}`, { params });
  }

  /**
   * @description To append params
   * @param paramsObj Object
   */
  appendParams(paramsObj: any): HttpParams {
    let params = new HttpParams();
    for (const prop in paramsObj) {
      if (paramsObj.hasOwnProperty(prop)) {
        params = params.append(prop, paramsObj[prop]);
      }
    }
    return params;
  }

  getXmlResponse(url: string, paramsObj?: any): Observable<any> {
    return this.http.get(`${this.baseApiUrl}${url}`, paramsObj);
  }
}
