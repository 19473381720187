<form [formGroup]="loginForm" class="mx-auto">
  <div class="form-group mb-2">
    <label class="label-input font-size-12 mb-0 ml-2"> {{ 'AUTH.USER_NAME_LABEL' | translate }}</label>
    <input class="mb-2" type="text" class="form-control input-shadow" formControlName="user" />
  </div>
  <div class="form-group position-relative">
    <label class="label-input font-size-12 mb-0 ml-2"> {{ 'AUTH.PASSWORD_LABEL' | translate }}</label>
    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control input-shadow" formControlName="password"
      autocomplete="off" />
    <img class="password-img cursor-pointer position-absolute" (click)="toggleFieldTextType()"
      src="{{fieldTextType? 'assets/images/password-no-visible.svg' : 'assets/images/password-view-icon.svg'}}"
      alt="resend-icon" />
  </div>
  <div class="custom-control custom-checkbox mb-3">
    <input formControlName="rememberMe" type="checkbox" class="custom-control-input mr-4" id="customCheckSettings" />
    <label class="label-input font-weight-400 custom-control-label checkbox-label pl-2 pt-1" for="customCheckSettings">
      {{ 'BUTTONS.REMEMBER_ME' | translate }}
    </label>
  </div>
  <button type="button" [disabled]="!loginForm.valid" (click)="routeToDashboard()"
    class="btn btn-primary w-100 mt-1 auth-button">
    {{ 'BUTTONS.SIGN_IN' | translate }}
  </button>
  <div class="text-right pt-3">
    <span (click)="routeToForgotPassword()" class="cursor-pointer link-button">
      {{ 'AUTH.FORGOT_PASSWORD' | translate }}
    </span>
  </div>
</form>