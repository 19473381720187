import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  /**
   *
   * @param content Form or model content as string
   * @returns returns formatted string
   */
   escapeBackSlash(content: string) {
    return content.replace(/\\/g, '').replace(/\n/g, '');
  }

}
