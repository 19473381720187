import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { StorageService } from 'src/app/services/storage/storage.service';
import appRouter from 'src/app/constants/api-route-constants';
import { ToasterServices } from 'src/app/services/toasterService/toaster.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardGuard implements CanActivate {
  constructor(private storageService: StorageService, private router: Router, private toastr: ToasterServices) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.storageService.get('token')) {
      this.toastr.warning('SESSION_EXPIRED');
      this.router.navigate([appRouter.auth.login]);
      return false;
    }
    return true;
  }
}
