import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from 'src/app/services/app.service';
import appRouter from '../../constants/api-route-constants';

@Component({
  selector: 'app-reset-link-sent',
  templateUrl: './reset-link-sent.component.html',
  styleUrls: ['./reset-link-sent.component.scss'],
})
export class ResetLinkSentComponent implements OnInit {
  email: any;

  constructor(private router: Router, private appService: AppService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email');
  }

  backToLogin(): void {
    this.router.navigate([appRouter.auth.login]);
  }

  resendResetLink(): void {
    this.appService.forgotPassword(this.email).subscribe(() => {});
  }
}
