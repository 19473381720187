<div class="my-2 w-100">
  <div class="w-100">
    <button class="drop-toggle btn flat select-arrow-custom form-control" (click)="showDropdownOptions()">
      <span>{{ placeHolder }}</span>
    </button>
    <div class="drop-down-option-container" *ngIf="showDropDown">
      <div class="drop-down-option overflow-auto">
        <div class="custom-control custom-checkbox cursor-pointer" *ngFor="let option of options">
          <input
            type="checkbox"
            class="custom-control-input width-20 height-20 mr-2"
            [id]="option.id"
            [(ngModel)]="option.checked"
            (change)="onCheck(option.id, $event)"
          />
          <label class="custom-control-label p-0 form-control border-0 h-100 cursor-pointer" for="{{ option.id }}">
            {{ option.name }}
          </label>
          <div class="my-2">
            <div class="custom-control custom-checkbox cursor-pointer" *ngFor="let subOption of option.subOptions">
              <input
                type="checkbox"
                class="custom-control-input width-20 height-20 mr-4"
                [id]="subOption.id"
                [(ngModel)]="subOption.checked"
                (change)="onCheck(subOption.id, $event)"
              />
              <label
                class="custom-control-label pl-2 p-0 form-control border-0 h-100 cursor-pointer"
                for="{{ subOption.id }}"
              >
                {{ subOption.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-start ml-3">
        <button class="btn ml-2 button-blue button-spacing btn-light font-size-14" (click)="selectAll()">
          {{ 'BUTTONS.SELECT_ALL' | translate }}
        </button>
        <button class="btn ml-2 button-blue button-spacing btn-light font-size-14" (click)="clearAll()">
          {{ 'BUTTONS.CLEAR' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
