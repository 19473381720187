<form [formGroup]="resetForm" (ngSubmit)="submit()" class="mx-auto">
  <div class="form-group pb-2 position-relative">
    <label class="label-input font-size-12 mb-0 ml-2">{{ 'AUTH.PASSWORD_LABEL' | translate }}</label>
    <input type="password" class="form-control input-shadow" formControlName="password" autocomplete="off" />
  </div>
  <div class="form-group pb-2 position-relative">
    <label class="label-input font-size-12 mb-0 ml-2">{{ 'AUTH.CONFIRM_PASSWORD' | translate }}</label>
    <input type="password" class="form-control input-shadow" formControlName="confirmPassword" autocomplete="off" />
    <div class="font-size-12 text-danger" *ngIf="f.confirmPassword.errors?.mustMatch">{{ 'AUTH.CONFIRM_PASSWORD' |
      translate }}</div>
  </div>
  <button type="submit" [disabled]="!resetForm.valid" class="btn btn-primary w-100 mt-1 auth-button">
    {{ 'BUTTONS.RESET_PASSWORD' | translate }}
  </button>
  <div class="pt-3 cursor-pointer link-button" (click)="backToLogin()">
    <img src="assets/images/back-arrow.svg" alt="back-arrow" />
    {{ 'AUTH.BACK_TO_LOGIN' | translate }}
  </div>
</form>