import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import API_CONSTANTS from '../../constants/api-constants';
import { BaseServicesService } from '../base-services/base-services.service';

@Injectable({
  providedIn: 'root',
})
export class ReportsService extends BaseServicesService {
  getMedicReports(): Observable<any> {
    return this.get('/admin-service/medic/_design/medic-client/_view/reports_by_date?limit=50&descending=true&skip=0');
  }

  postMedicId(data: any): Observable<any> {
    return this.post('/admin-service/medic/_design/medic/_view/doc_summaries_by_id', data);
  }

  getTaskReports(skip: number): Observable<any> {
    return this.get(`${API_CONSTANTS.reports.byDate}${skip}`);
  }

  getAllDocByReportId(data: any): Observable<any> {
    return this.post(`${API_CONSTANTS.reports.getAllDocByReportId}`, data);
  }

  getResources(): Observable<any> {
    return this.get(`${API_CONSTANTS.reports.getResources}`);
  }

  getForms(): Observable<any> {
    return this.get(`${API_CONSTANTS.reports.getForms}`);
  }

  getFormDetails(formName: any): Observable<any> {
    return this.get(`${API_CONSTANTS.reports.getFormDetails}${formName}`);
  }

  getFormImageIcon(imageName: any): Observable<any> {
    return this.get(`${API_CONSTANTS.reports.getFormImageIcon}${imageName}`);
  }

  getReportsByPlace(keys: any) {
    return this.get(`${API_CONSTANTS.reports.byPlace}${keys}`);
  }

  getReportsByForm(keys: any) {
    return this.get(`${API_CONSTANTS.reports.byForm}${keys}`);
  }

  getReportsByDate(startKey: any, endKey: any) {
    return this.get(`${API_CONSTANTS.reports.getDateStart}${startKey}${API_CONSTANTS.reports.getDateEnd}${endKey}`);
  }

  getReportsBySearch(startKey: any, endKey: any) {
    return this.get(
      `${API_CONSTANTS.reports.searchReportStart}${startKey}${API_CONSTANTS.reports.searchReportEnd}${endKey}`
    );
  }

  getPlaceList(key: any) {
    return this.get(`${API_CONSTANTS.reports.getPlaceList}${key}`);
  }

  getViewReportDetail(id: string) {
    return this.get(`${API_CONSTANTS.reports.getViewReportDetail}${id}`);
  }

  reviewReport(data: any) {
    return this.post(`${API_CONSTANTS.reports.reviewReport}`, data);
  }

  getFormXmlDetail(id: String) {
    const opts = {
      responseType: 'text',
    };
    return this.getXmlResponse(`${API_CONSTANTS.reports.getFormXmlDetails}${id}${API_CONSTANTS.reports.Xml}`, opts);
  }
}
