import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { base64Prefix } from 'src/app/constants/constants';
import {
  ApplicationSettingsService,
  IPreviewFormData,
} from 'src/app/services/application-settings/application-settings.service';
import { FormPreview } from 'src/app/services/form-preview-base';
import { PeopleService } from 'src/app/services/people/people.service';
import { UtilService } from 'src/app/services/util/util.service';

import appRouter from '../../../../constants/api-route-constants';

const BRANCH_FORM_ID = 'form:contact:district_hospital:create';
const BRANCH_FORM_TYPE = 'district_hospital';
@Component({
  selector: 'app-branch-detail',
  templateUrl: './branch-detail.component.html',
  styleUrls: ['./branch-detail.component.scss'],
})
export class BranchDetailComponent extends FormPreview implements OnInit, OnDestroy {
  isEmpty: any = false;
  active = 1;
  branch: any = {};
  supervisorDetails: any = [];
  primaryContact: any = {};
  chwAreaDetails: any = [];
  individualUsers: any = [];
  branchIcon: any;
  resources: any;
  private subscriptions: Subscription[] = [];
  individualSkip = 0;
  startKey = '';
  endKey = '';

  constructor(
    private router: Router,
    private peopleService: PeopleService,
    private modalService: NgbModal,
    private utilService: UtilService,
    private applicationSettingsService: ApplicationSettingsService
  ) {
    super(modalService, utilService);
  }

  ngOnInit(): void {
    this.peopleService.branchUpdated.subscribe((data) => {
      this.branch = data;
      const contactId = data.contact && data.contact._id;
      this.getBranchDetails(data._id, contactId);
    });
    this.getResources();

    // Settings Data
    const settingsSubs = this.applicationSettingsService.getApplicationSettingsObs().subscribe((settings) => {
      this.setUiSettings(settings.ui_customization);
    });
    this.subscriptions.push(settingsSubs);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  /**
   * <p>
   *  Route back to supervisor detail given supervisor detail.
   * </p>
   *
   * @param person
   */
  routeToDetailPage(person: any): void {
    const id = person._id;
    this.router.navigate([appRouter.home.peopleMenu.supervisorDetail, id]);
  }

  /**
   * <p>
   *  Route back to CHW Area detail given area detail.
   * </p>
   *
   * @param area
   */
  routeToChwDetailPage(area: any): void {
    const id = area._id;
    this.peopleService.setAreaValue(area);
    this.router.navigate([appRouter.home.peopleMenu.chwDetail, id]);
  }

  /**
   * @description Gets the form HTML and XML to render form
   */
  public editBranchModal(): void {
    this.applicationSettingsService.getFormHtml(BRANCH_FORM_ID).subscribe((formData) => {
      this.getFormModelValue(formData);
    });
  }

  /**
   * <p>
   *  Get the branch Details like area and persons given the
   * branch id and primary contact id.
   * </p>
   *
   * @param id
   * @param contactId
   */
  private getBranchDetails(id: string, contactId: string): void {
    this.startKey = '["' + id + '"]';
    this.endKey = '["' + id + '", {}]';
    this.individualSkip = 0;
    this.individualUsers = [];
    this.peopleService
      .getDocDetailsByParentId(encodeURIComponent(this.startKey), encodeURIComponent(this.endKey))
      .subscribe((res) => {
        this.primaryContact = {};
        this.supervisorDetails = [];
        this.chwAreaDetails = [];
        res.rows.forEach((row: any) => {
          if (row.doc.type === 'person') {
            if (contactId && row.doc._id === contactId) {
              this.primaryContact.primaryContact = true;
              this.primaryContact = row.doc;
            } else {
              this.supervisorDetails.push(row.doc);
            }
          }
          if (row.doc.type === 'health_center') {
            this.chwAreaDetails.push(row.doc);
          }
        });
      });
    this.getIndividualUser();
  }


  public getIndividualUser(){
    this.peopleService
      .getIndividualUsersByBranch(encodeURIComponent(this.startKey), encodeURIComponent(this.endKey), this.individualSkip)
      .subscribe((users: any) => {
        users.rows.forEach((user: any) => {
          this.individualUsers.push(user.doc);
        });
      });
      this.individualSkip = this.individualSkip+10;
  }

  /**
   * <p>
   *   Get Icons from the resources.
   * </p>
   */
  private getResources() {
    this.peopleService.getResources().subscribe((res: any) => {
      this.resources = res.resources;
      this.peopleService.getIcon(this.resources.branch).subscribe((image: any) => {
        if (image) {
          this.branchIcon = base64Prefix + image.base64;
        }
      });
    });
  }

  /**
   * @description Gets the already user entered form values and opens preview modal
   * @param formData Form Data with HTML and xml for preview
   *
   */
  private getFormModelValue(formData: IPreviewFormData): void {
    this.peopleService.getFormModelXml(this.branch._id).subscribe((formModelXml) => {
      formData.formValue = formModelXml;
      // Opens the preview Modal and subscribe to form submit event
      this.openPreviewModal(formData, 'Edit Intervention').subscribe((formValue) => {
        this.updateBranch(formValue);
      });
    });
  }

  /**
   *
   * @param formValue user entered form value in xml format
   */
  private updateBranch(formValue: string): void {
    let updatedBranch;
    updatedBranch = {
      type: BRANCH_FORM_TYPE,
      reported_date: Date.now(),
      xml: formValue,
    };
    this.peopleService.updateBranchFormData(this.branch._id, updatedBranch).subscribe(
      (res) => {
        this.peopleService.getBranches();
      },
      (error) => {
        updatedBranch = null;
      }
    );
  }
}
