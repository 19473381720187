import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ExportToCsv } from 'export-to-csv';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PeopleService } from 'src/app/services/people/people.service';
import { ApplicationSettingsService } from 'src/app/services/application-settings/application-settings.service';
import { UtilService } from 'src/app/services/util/util.service';
import { base64Prefix } from 'src/app/constants/constants';
import { FormPreview } from 'src/app/services/form-preview-base';

const BRANCH_FORM_ID = 'form:contact:district_hospital:create';
const BRANCH_FORM_TYPE = 'district_hospital';
@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss'],
})
export class PeopleComponent extends FormPreview implements OnInit, OnDestroy {
  @ViewChild('modalContent') modalTemplate: any;
  searchValue: any = '';
  branches: any = [];
  branchesCopy: any = [];
  supervisorDetails: any = [];
  primaryContact: any = {};
  chwAreaDetails: any = [];
  arrayId: any;
  content: any;
  isModalVisible = false;
  roleFormGroup: FormGroup;
  roleForm: any = {
    role: '',
    translationKey: '',
  };
  selectedBranchId = '';
  branchIcon: any;
  data: any = [];
  resources: any;
  isNoBranchSelected = true;
  private subscriptions: Subscription[] = [];
  branchId: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private peopleService: PeopleService,
    private router: Router,
    private modalService: NgbModal,
    private applicationSettingsService: ApplicationSettingsService,
    private utilService: UtilService
  ) {
    super(modalService, utilService);
    this.roleFormGroup = this.formBuilder.group(this.roleForm);
  }

  ngOnInit(): void {
    this.getResources();
    this.peopleService.getBranches();
    this.subscribeToBranchesObs();
    const settingsSubs = this.applicationSettingsService.getApplicationSettingsObs().subscribe((settings) => {
      this.setUiSettings(settings.ui_customization);
    });
    this.subscriptions.push(settingsSubs);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  /**
   * <p>
   *  On Branch click navigate the user to branch-detail page and
   * update the branch, area and family values.
   * </p>
   *
   * @param branch
   */
  onBranchClick(branch: any): void {
    this.selectedBranchId = branch._id;
    this.isNoBranchSelected = false;
    this.peopleService.setBranchValue(branch);
    this.peopleService.setAreaValue('');
    this.peopleService.setFamilyValue('');

    this.router.navigate(['/people/branch-detail']);
  }

  /**
   * To show the modal template
   *
   * @param componentTemplate - is the component template ref
   */
  showModalTemplate() {
    this.initializeRoleForm();
    this.isModalVisible = true;
    this.content = this.modalTemplate;
  }

  /**
   * To initialize the target form
   */
  initializeRoleForm(): void {
    this.roleFormGroup.patchValue(this.roleForm);
  }

  /**
   * To act on form submit
   */
  onFormSubmit() {
    this.isModalVisible = false;
  }

  /**
   * To act on modal close
   */
  onModalClose() {
    this.isModalVisible = false;
  }

  /**
   * <p>
   *  Search the branches depends on the name
   * </p>
   *
   * @param event
   */
  onSearchClick(event: any): void {
    if (event.keyCode === 13) {
      this.branches = [];
      this.branchId = []
      this.searchValue = event.target.value;
      this.branchesCopy.forEach((branch: any) => {
        if (branch.name.toLowerCase().startsWith(this.searchValue.toLowerCase())) {
          this.branches.push(branch);
          this.branchId.push(branch._id);
        }
      });
    }
  }

  /**
   * <p>
   *   Export Branch and its details to a CSV file
   * </p>
   */
  exportToCsv() {
    this.data = [];
    const date = new Date();
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'contacts-' + date.getFullYear() + date.getUTCMonth() + date.getUTCDate() + date.getUTCMinutes(),
    };
    let branchKey: any = [];
    this.branchId.map((id: any) => {
      branchKey.push('["' + id + '"]');
    });

    const key = '[' + branchKey.toString() + ']';
    this.peopleService.getContactsByType(encodeURIComponent(key)).subscribe((res) => {
      for (const row of res.rows) {
        const value = { id: '', rev: '', name: '', patient_id: '', type: '' };
        value.id = row.value[0];
        value.rev = row.value[1];
        value.name = row.value[2];
        value.patient_id = row.value[3] === null ? '' : row.value[3];
        value.type = row.value[4];
        this.data.push(value);
      }
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(this.data);
    });
  }

  /**
   * @description Gets the HTML for branch form and open preview
   */
  newBranchModal(): void {
    this.applicationSettingsService.getFormHtml(BRANCH_FORM_ID).subscribe((formData) => {
      // Open Preview Form and listen for form submit event
      this.openPreviewModal(formData, 'New Intervention').subscribe((data) => {
        this.addNewBranch(data);
      });
    });
  }

  /**
   *
   * @param formValue user entered form value in xml format
   */
  private addNewBranch(formValue: string): void {
    let newBranch;
    newBranch = {
      type: BRANCH_FORM_TYPE,
      reported_date: Date.now(),
      xml: formValue,
    };

    this.peopleService.saveBranchFormData(newBranch).subscribe(
      (res) => {
        this.peopleService.getBranches();
        this.selectedBranchId = res[0].id;
      },
      (error) => {
        newBranch = null;
      }
    );
  }

  /**
   * @description Method used to fetch branches
   */
  private subscribeToBranchesObs(): void {
    const branchObs = this.peopleService.branchesListObs().subscribe((branches) => {
      if (branches.length === 0) {
        return;
      }
      this.branches = branches.map((row: any) => {
        this.branchId.push(row.value._id);
        return row.value
      });
      this.branchesCopy = branches.map((row: any) => row.value);
      if (this.selectedBranchId) {
        // If already selected Id available show its detail
        this.onBranchClick(this.branches.find((branch: any) => branch._id === this.selectedBranchId));
        return;
      }
      this.onBranchClick(this.branches[0]);
    });
    this.subscriptions.push(branchObs);
  }

  /**
   * <p>
   *   Get Icons from the resources.
   * </p>
   */
  private getResources() {
    this.peopleService.getResources().subscribe((res: any) => {
      this.resources = res.resources;
      this.peopleService.getIcon(this.resources.branch).subscribe((image: any) => {
        if (image) {
          this.branchIcon = base64Prefix + image.base64;
        }
      });
    });
  }
}
