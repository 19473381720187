import { Component, OnInit } from '@angular/core';

import { AppService } from 'src/app/services/app.service';
import { ApplicationSettingsService } from 'src/app/services/application-settings/application-settings.service';
import appRouter from '../../constants/api-route-constants';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss'],
})
export class SideNavbarComponent implements OnInit {
  sideBar = {
    header_side_bar: '',
    icons_tabs: {
      primary: '',
      secondary: '',
      tertiary: '',
    },
  };
  isSideBarColor = false;
  navbarMenus = [
    // {
    //   tabIcon: 'chat',
    //   tabLink: appRouter.home.messages,
    // },
    {
      tabIcon: 'description',
      tabLink: appRouter.home.reports,
    },
    {
      tabIcon: 'groups',
      tabLink: appRouter.home.people,
    },
    {
      tabIcon: 'location_on',
      tabLink: appRouter.home.location,
    },
  ];

  navbarSettings = [
    { tabIcon: 'manage_accounts', tabLink: appRouter.home.userSetting },
    { tabIcon: 'tune', tabLink: appRouter.home.settings },
  ];

  constructor(private appService: AppService, private applicationSettingsService: ApplicationSettingsService) { }

  ngOnInit(): void {
    this.getSideBarColors();

    this.appService.isColorUpdated.subscribe((data) => {
      this.isSideBarColor = data;
      if (this.isSideBarColor) {
        this.getSideBarColors();
      }
    });
  }

  getSideBarColors(): void {
    this.applicationSettingsService.getSettings().subscribe((data) => {
      this.sideBar = data.ui_customization.web.application_colors;
    });
  }
}
