<div class="p-3 supervisor-detail">
  <div class="d-flex cursor-pointer" (click)="routeBackToBranch(branch)">
    <img src="assets/images/back-button.svg" />
    <span class="ml-2 font-size-14 link-button">
      {{ 'BUTTONS.BACK' | translate }}
    </span>
  </div>
  <div class="mt-3 pb-3 border-bottom d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <img width="60" height="60" src="{{ personIcon }}" />
      <div class="pl-3">
        <div class="font-size-20 font-weight-500 text-capitalize">{{ supervisor.person_name }}</div>
        <div *ngIf="family">
          <span class="text-capitalize link-button">{{ family.contact.name }}</span>.
          <span class="text-capitalize link-button">{{ area.name || empty }}</span>
        </div>
        <div *ngIf="!family">
          <span class="text-capitalize link-button">{{ branch.name }}</span>
        </div>
      </div>
    </div>
    <div>
      <!-- Commented out edit person functionality for now -->
      <!-- <button class="btn btn-outline-secondary font-size-14">
        {{ 'BUTTONS.EDIT_PERSON' | translate }}
      </button> -->
    </div>
  </div>

  <div class="d-flex py-2 text-capitalize">
    <div>
      <strong>{{ 'PEOPLE.AGE' | translate }}</strong> {{ supervisor.person_age }} Years
    </div>
    <div *ngIf="supervisor.phone" class="ml-3">
      <strong>{{ 'PEOPLE.PHONE' | translate }}</strong> {{ supervisor.phone }}
    </div>
  </div>
  <div class="overflow-auto set-detail-width p-2">
    <div *ngFor="let report of reports; let i = index"
      class="d-flex cursor-pointer border justify-content-between border-radius-4 mt-2 p-2">
      <div class="d-flex align-items-center">
        <img class="pb-1 pr-1" width="60" height="60" src="{{ report.icon }}" />
        <div class="pl-2">
          <div class="font-size-14 font-weight-500 text-capitalize">{{ report.displayName }}</div>
          <div class="font-size-14 font-weight-400 text-capitalize">{{ report.formname }}</div>
        </div>
      </div>
      <div class="font-size-10">{{ report.days }}</div>
    </div>
    <div *ngIf="!reports.length" class="d-flex cursor-pointer border justify-content-between border-radius-4 mt-4 p-3">
      <div class="d-flex align-items-center font-size-14">{{ 'PEOPLE.NO_REPORTS_FOUND' | translate }}</div>
    </div>
  </div>
</div>