import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToasterServices {
  private options = {
    progressBar: true,
  };
  constructor(private toastrService: ToastrService) {}

  public success(message: string): void {
    this.toastrService.success(message, '', this.options);
  }

  public error(message: string): void {
    this.toastrService.error(message, '', this.options);
  }

  public info(message: string): void {
    this.toastrService.info(message, '', this.options);
  }

  public warning(message: string): void {
    this.toastrService.warning(message, '', this.options);
  }
}
