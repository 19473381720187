/**
 * @description get 3rd party key configuration dynamically based on route configuration
 * @returns object
 */
export const environment = {
  production: true,
  jsonRuleEditor: {
    editorPath: '/json-rule-editor',
    editorOrigin: window.location.origin
  },
  baseApiUrl: '',
  i18Path: 'assets/i18n/',
};
