import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
/* @ts-ignore */
import { Form } from 'enketo-core';

@Component({
  selector: 'app-form-preview',
  templateUrl: './form-preview.component.html',
  styleUrls: ['./form-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[style.display]': '"flex"',
    '[style.flex-direction]': '"column"',
  },
})
export class FormPreviewComponent implements OnInit, AfterViewInit {
  @Output() formValidate = new EventEmitter();
  @ViewChild('formContainer', { static: false }) formContainer: ElementRef | undefined;
  formHTML = '';
  formXML = '';
  formModel = '';
  header = '';
  safeHTML: any;
  form: any;
  uiSettings: any = null;

  constructor(private domSanitizer: DomSanitizer, public activeModal: NgbActiveModal) {}

  ngAfterViewInit(): void {
    this.initializeForm();
  }

  ngOnInit(): void {
    this.safeHTML = this.domSanitizer.bypassSecurityTrustHtml(this.formHTML);
  }

  initializeForm(): void {
    const formElem = this.formContainer?.nativeElement?.children[0];
    const data = {
      // required string of the default instance defined in the XForm
      modelStr: this.formXML,
      instanceStr: this.formModel,
      // optional boolean whether this instance has ever been submitted before
      submitted: false,
    };
    // Form-specific configuration
    const options = {};

    this.form = new Form(formElem, data, options);
    this.form.init();
  }

  validate(): void {
    this.form.validate().then((valid: boolean) => {
      if (valid) {
        this.activeModal.dismiss();
        const xmlString = this.form.getDataStr();
        this.formValidate.emit(xmlString);
      }
    });
  }
}
