import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToasterServices } from '../../services/toasterService/toaster.service';
import { StorageService } from '../../services/storage/storage.service';
import { LocalStorageService } from '../../services/storage/localStorage.service';
import { AppService } from '../../services/app.service';
import appRouter from '../../constants/api-route-constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm!: FormGroup;
  public fieldTextType = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private appService: AppService,
    private storageService: StorageService,
    private localStorageService: LocalStorageService,
    private toastr: ToasterServices
  ) {}

  ngOnInit(): void {
    this.initLoginForm();
  }

  routeToForgotPassword(): void {
    this.router.navigate([appRouter.auth.forgotPassword]);
  }

  routeToDashboard(): void {
    this.appService.postSignIn(this.loginForm.value).subscribe(
      (data) => {
        this.localStorageService.set('rememberMe', this.loginForm.value.rememberMe);
        if (this.loginForm.value.rememberMe) {
          this.localStorageService.set('user', this.loginForm.value.user);
          this.localStorageService.set('password', this.loginForm.value.password);
        } else {
          this.localStorageService.set('user', '');
          this.localStorageService.set('password', '');
          this.localStorageService.set('rememberMe', '');
        }
        if (data.userCtx.roles[0] === 'national_admin' || data.userCtx.roles[0] === 'super_admin') {
          this.router.navigate([appRouter.home.reports]);
          this.storageService.set('token', data.token);
          this.storageService.set('refreshToken', data.refreshToken);
          this.storageService.set('userName', data.userCtx.name);
          this.storageService.set('role', data.userCtx.roles[0]);
          this.toastr.success('Successfully signed in');
        } else {
          this.router.navigate([appRouter.auth.login]);
          this.toastr.error('This user cannot login');
        }
      },
      (error) => {
        this.toastr.error('Error while signing in');
      }
    );
  }

  initLoginForm(): void {
    // if (data.userCtx.roles[0] === 'national_admin' || data.userCtx.roles[0] === 'super_admin') {
    this.router.navigate([appRouter.auth.login]);
    // this.toastr.success('Successfully signed in');
    // } else {
    // this.toastr.error('This user cannot login');
    // }
    this.loginForm = this.fb.group({
      user: [this.localStorageService.get('user'), Validators.required],
      password: [this.localStorageService.get('password'), Validators.required],
      rememberMe: [this.localStorageService.get('rememberMe')],
    });
  }

  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }
}
