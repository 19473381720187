<div class="container-fluid">
  <div class="d-flex justify-content-between pb-3">
    <div class="font-size-24 font-weight-500 pl-3">
      {{ 'PEOPLE.HEADER' | translate }}
    </div>
    <div class="d-flex">
      <button (click)="exportToCsv()" class="btn ml-2 button-blue link-button btn-light font-size-14">
        <img class="pb-1 pr-1" src="assets/images/download-icon.svg" />
        {{ 'BUTTONS.EXPORT' | translate }}
      </button>
      <button class="btn ml-2 btn-secondary font-size-14" (click)="newBranchModal()">
        <img class="pb-1 pr-1" src="assets/images/add-icon.svg" />
        {{ 'BUTTONS.NEW_BRANCH' | translate }}
      </button>
    </div>
  </div>
  <div class="card people col-12">
    <div class="row justify-content-between">
      <div class="col-lg-4 col-md-5 col-sm-12 col-12 search-height pr-0 pt-3">
        <div class="container">
          <div class="d-flex mb-4">
            <input type="text" class="form-control" placeholder="Search Branch.." (keyup)="onSearchClick($event)" />
            <img class="pb-1 pr-1 search-icon position-absolute" src="assets/images/search-icon.svg" />
          </div>
          <div class="overflow-auto people-search-height">
            <div *ngFor="let branch of branches; let i = index" (click)="onBranchClick(branch)"
              class="font-size-16 d-flex text-capitalize align-items-center py-1 font-weight-500 cursor-pointer pl-3 mb-4 overflow-auto"
              [ngClass]="{ 'border-blue': branch._id == selectedBranchId }">
              <span class="search-img mr-2">
                <img src="{{ branchIcon }}" style="width: 40px; height: 30px" />
                <!-- <img src="/assets/images/mock-icon.svg" /> -->
              </span>
              {{ branch.name }}
            </div>
            <div *ngIf="!branches.length" class="font-size-14 font-weight-400 cursor-pointer pl-3 mb-4">
              {{ 'PEOPLE.NO_BRANCHES_FOUND' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-7 col-sm-12 col-12 pb-3 pl-0 d-flex justify-content-center align-items-center"
        *ngIf="isNoBranchSelected">
        <div class="no-branch-selected font-size-18">NO BRANCH SELECTED</div>
      </div>
      <div *ngIf="!isNoBranchSelected" class="col-lg-8 col-md-7 col-sm-12 col-12 pb-3 pl-0">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<!-- <ng-template #modalContent let-modal>
  <div class="modal-body px-3 py-0">
    <form [formGroup]="roleFormGroup">
      <div class="mb-2 ml-1">
        <input type="text" formControlName="role" class="form-control modal-input mt-1 border-radius-4"
          placeholder="To" />
      </div>
      <div class="mb-2">
        <textarea class="form-control modal-input mt-1 border-radius-4 ml-1" id="textArea" name="textArea" rows="4"
          cols="50">
 Message</textarea>
      </div>
    </form>
  </div>
</ng-template> -->

<!-- <app-form-modal
  *ngIf="isModalVisible"
  [content]="content"
  (formSubmit)="onFormSubmit()"
  (modalClose)="onModalClose()"
  [modalOptions]="modalOptions"
  [title]="'REPORTS.SEND_MESSAGE' | translate"
  [modalStyle]="'modal-content-md'"
>
</app-form-modal> -->