import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PeopleRoutingModule } from './people-routing.module';
import { PeopleComponent } from './people.component';
import { SupervisorDetailComponent } from './components/supervisor-detail/supervisor-detail.component';
import { BranchDetailComponent } from './components/branch-detail/branch-detail.component';
import { ChwAreaDetailsComponent } from './components/chw-area-details/chw-area-details.component';
import { HouseholdDetailsComponent } from './components/household-details/household-details.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    PeopleComponent,
    SupervisorDetailComponent,
    BranchDetailComponent,
    ChwAreaDetailsComponent,
    HouseholdDetailsComponent,
  ],
  imports: [CommonModule, PeopleRoutingModule, NgbModule, TranslateModule,  InfiniteScrollModule],
})
export class PeopleModule {}
