import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmModal') modalTemplate: any;
  @Input() modalOptions: any;
  @Output() modalClose = new EventEmitter();
  @Output() formSubmit = new EventEmitter();

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.modalService.open(this.modalTemplate, this.modalOptions).result.then(
      (result) => {
        this.formSubmit.emit();
      },
      (reason) => {
        this.modalClose.emit();
      }
    );
  }
}
