<ng-template #confirmModal let-modal>
  <div class="modal-content border-0 confirm-modal text-center p-2">
    <div class="modal-body">
      <p class="m-0">
        <strong>{{ 'LABELS.DELETE_CONFIRMATION' | translate }}</strong>
      </p>
    </div>
    <div class="modal-footer border-0 justify-content-center">
      <button type="button" class="btn btn-outline-secondary font-size-14" (click)="modal.dismiss('Cancel click')">
        {{ 'BUTTONS.CANCEL' | translate }}
      </button>
      <button type="button" class="btn btn-danger font-size-14" (click)="modal.close('Save click')">
        {{ 'BUTTONS.DELETE' | translate }}
      </button>
    </div>
  </div>
</ng-template>
