import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationSettingsService } from '../application-settings/application-settings.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsResolver implements Resolve<any> {
  constructor(private appSettingsService: ApplicationSettingsService) {}
  resolve(): Observable<any> {
    return this.appSettingsService.getSettings();
  }
}
