import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from 'src/app/services/app.service';
import { ApplicationSettingsService } from 'src/app/services/application-settings/application-settings.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import appRouter from '../../constants/api-route-constants';
import { base64Prefix } from '../../constants/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isHeaderColor = false;
  userName: string | null = '';
  userRole: string | null = '';
  imagePath = '';
  headerData = {
    header_side_bar: '',
    icons_tabs: {
      primary: '',
      secondary: '',
      tertiary: '',
    },
  };

  constructor(
    private router: Router,
    private applicationSettingsService: ApplicationSettingsService,
    private appService: AppService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.getHeaderColor();
    this.userName = this.storageService.get('userName');
    this.userRole = this.storageService.get('role');
    this.applicationSettingsService.getMobileLogo().subscribe((data) => {
      Object.keys(data._attachments).map((val) => {
        if (val === data.resources.header_logo) {
          this.applicationSettingsService.getIconFromBranding(val).subscribe((img: any) => {
            this.imagePath = base64Prefix + img.base64;
          });
        }
      });
    });
    this.appService.isColorUpdated.subscribe((data) => {
      this.isHeaderColor = data;
      if (this.isHeaderColor) {
        this.getHeaderColor();
        this.applicationSettingsService.getMobileLogo().subscribe((data) => {
          Object.keys(data._attachments).map((val) => {
            if (val === data.resources.header_logo) {
              this.applicationSettingsService.getIconFromBranding(val).subscribe((img: any) => {
                this.imagePath = base64Prefix + img.base64;
              });
            }
          });
        });
      }
    });
  }

  getHeaderColor(): void {
    this.applicationSettingsService.getSettings().subscribe((data) => {
      this.headerData = data.ui_customization.web.application_colors;
    });
  }

  logout(): void {
    this.appService.logout().subscribe((data) => {
      this.storageService.clearStorage();
      this.router.navigate([appRouter.auth.login]);
    });
  }
}
