export default {
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    resetLink: '/reset-link',
  },
  home: {
    messages: '/messages',
    people: '/people',
    reports: '/reports',
    location: '/location',
    settings: '/settings',
    userSetting: '/user-settings',
    peopleMenu: {
      branchDetail: '/people/branch-detail/',
      supervisorDetail: '/people/supervisor-detail/',
      chwDetail: '/people/chw-detail/',
      houseHoldDetail: '/people/household-detail/',
    },
    applicationSettings: {
      general: '/settings/general',
      language: '/settings/language',
      forms: '/settings/forms',
      targets: '/settings/targets',
      uiCustom: '/settings/ui-customization',
      icons: '/settings/icon-upload',
      formUpload: '/admin-service/convertToXml',
      outgoingMessages: '/settings/outgoing-messages',
      resources: '/resource-service/resources',
      formData: '/admin-service/medic/_design/medic-client/_view/forms?include_docs=true',
      languages: `/admin-service/medic/_design/medic-client/_view/doc_by_type`,
      uiCustomization: {
        getBranding: '/resource-service/branding',
        getResources: '/resource-service/resources',
        getSettings: '/admin-service/api/v1/settings',
      },
    },
    userSettings: {
      users: '/user-settings/users',
      roles: '/user-settings/roles',
      permissions: '/user-settings/permissions',
    },
  },
  userSettings: {
    settings:
      '/admin-service/medic/_design/medic-client/_view/doc_by_type?include_docs=true&key=%5B%22user-settings%22%5D',
    users: '/admin-service/api/v1/users',
  },
  adminService: '/admin-service',
  bulkDocks: '/document-service-up/medic/_bulk_docs',
};
