import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLoading$$ = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading$$.asObservable();
  message = '';

  showLoading(message = ''): void {
    this.isLoading$$.next(true);
    this.message = message;
  }

  hideLoading(): void {
    this.isLoading$$.next(false);
  }
}
