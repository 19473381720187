import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent {
  @Input() options: any = [];
  @Input() placeHolder = '';
  @Input() showDropDown = false;
  @Output() checkInputChange = new EventEmitter();
  @Output() modifyOptions = new EventEmitter();
  @Output() dropDownOptionChange = new EventEmitter();

  onCheck(checkId: any, event: Event) {
    this.checkInputChange.emit(checkId);
  }

  selectAll(): void {
    this.modifyOptions.emit(true);
  }

  clearAll(): void {
    this.modifyOptions.emit(false);
  }

  showDropdownOptions(): void {
    this.dropDownOptionChange.emit(false);
  }
}
