import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import { MessagesComponent } from '../dashboard/messages/messages.component';
import { LocationComponent } from './location/location.component';
import { ReportsComponent } from '../dashboard/reports/reports.component';
import { DashboardGuard } from 'src/app/services/auth/guards/dashboard/dashboard.guard';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [DashboardGuard],
    children: [
      { path: 'messages', component: MessagesComponent },
      { path: 'location', component: LocationComponent },
      { path: 'reports', component: ReportsComponent },
      {
        path: 'people',
        loadChildren: () => import('./people/people.module').then((m) => m.PeopleModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('../application-settings/application-settings.module').then((m) => m.ApplicationSettingsModule),
      },
      {
        path: 'user-settings',
        loadChildren: () => import('../user-settings/user-settings.module').then((m) => m.UserSettingsModule),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'MessagesComponent',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
