import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PeopleComponent } from './people.component';
import { BranchDetailComponent } from './components/branch-detail/branch-detail.component';
import { SupervisorDetailComponent } from './components/supervisor-detail/supervisor-detail.component';
import { ChwAreaDetailsComponent } from './components/chw-area-details/chw-area-details.component';
import { HouseholdDetailsComponent } from './components/household-details/household-details.component';

const routes: Routes = [
  {
    path: '',
    component: PeopleComponent,
    children: [
      { path: 'branch-detail', component: BranchDetailComponent },
      { path: 'supervisor-detail/:id', component: SupervisorDetailComponent },
      { path: 'chw-detail/:id', component: ChwAreaDetailsComponent },
      { path: 'household-detail/:id', component: HouseholdDetailsComponent }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PeopleRoutingModule { }
