import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BaseServicesService } from '../base-services/base-services.service';
import API_CONSTANTS from '../../constants/api-constants';
import appRouter from '../../constants/api-route-constants';

export interface IPreviewFormData {
  model: string;
  form: string;
  formValue: string;
}
@Injectable({
  providedIn: 'root',
})
export class ApplicationSettingsService extends BaseServicesService {
  private applicationSettingsSubject = new BehaviorSubject<any>(null);
  private applicationsettings$ = this.applicationSettingsSubject.asObservable();

  getSettings(): Observable<any> {
    return this.get(appRouter.home.applicationSettings.uiCustomization.getSettings);
  }

  saveSettings(body: any, params?: any): Observable<any> {
    return this.put(appRouter.home.applicationSettings.uiCustomization.getSettings, body, params);
  }

  getResources(): Observable<any> {
    return this.get(appRouter.home.applicationSettings.resources);
  }

  getForms(): Observable<any> {
    return this.get(API_CONSTANTS.applicationSettings.formList);
  }

  getFormData(formId: string): Observable<any> {
    return this.get(`${API_CONSTANTS.applicationSettings.formData}/${formId}`);
  }

  /**
   *
   * @param formId Form Id to get HTML
   * @returns The HTML and model content for form to render
   */
  getFormHtml(formId: string): Observable<IPreviewFormData> {
    return this.get(API_CONSTANTS.applicationSettings.formHTML.replace('formId', formId));
  }

  getLanguages(params?: any): Observable<any> {
    return this.get(appRouter.home.applicationSettings.languages, params);
  }

  saveLanguage(url: any, body: any): Observable<any> {
    return this.put(`${API_CONSTANTS.applicationSettings.adminService}/${url}`, body);
  }

  saveBulkDocs(docs: any): Observable<any> {
    return this.post(API_CONSTANTS.applicationSettings.bulkDocks, docs);
  }

  getMobileLogo(): Observable<any> {
    return this.get(API_CONSTANTS.applicationSettings.branding);
  }

  updateResources(defaultIconName: string, data: any): Observable<any> {
    return this.put(`${API_CONSTANTS.applicationSettings.resources}/${defaultIconName}`, data);
  }

  getIconFromBranding(iconName: string): Observable<any> {
    return this.get(`${API_CONSTANTS.applicationSettings.imageFromBranding}/${iconName}`);
  }

  getIconFromResources(iconName: string): Observable<any> {
    return this.get(`${API_CONSTANTS.applicationSettings.imageFromResources}/${iconName}`);
  }

  updateBranding(defaultIconName: string, data: any): Observable<any> {
    return this.put(`${API_CONSTANTS.applicationSettings.branding}/${defaultIconName}`, data);
  }

  getMobileIcon(): Observable<any> {
    return this.get(appRouter.home.applicationSettings.uiCustomization.getResources);
  }

  /**
   *
   * @param applicationSettings Application settings
   */
  setApplicationSettings(applicationSettings: any) {
    this.applicationSettingsSubject.next(applicationSettings);
  }

  /**
   *
   * @returns behavior subject with application settings
   */

  getApplicationSettingsObs(): Observable<any> {
    return this.applicationsettings$;
  }

  getAPI(id: any): Observable<any> {
    return this.get(`/admin-service/document/medic/${id}`);
  }

  deleteAPI(data: any): Observable<any> {
    return this.post(`/document-service-up/medic/_bulk_docs`, data);
  }

  deleteFrom(id: any): Observable<any> {
    return this.put(`/admin-service/${id}/delete`);
  }
}
