import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { base64Prefix } from 'src/app/constants/constants';
import { PeopleService } from 'src/app/services/people/people.service';

import appRouter from '../../../../constants/api-route-constants';

@Component({
  selector: 'app-chw-area-details',
  templateUrl: './chw-area-details.component.html',
  styleUrls: ['./chw-area-details.component.scss'],
})
export class ChwAreaDetailsComponent implements OnInit {
  active = 1;
  households: any = [];
  individuals: any = [];
  branch: any = {};
  area: any = {};
  areaIcon: any;
  resources: any = {};

  constructor(private router: Router, private route: ActivatedRoute, private peopleService: PeopleService) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.getChwAreaDetails(id);
    this.updateParentValues();
    this.getResources();
  }

  /**
   * <p>
   *  Route to the Household Detail given household details
   * </p>
   *
   * @param household
   */
  routeToHouseHoldDetail(household: any): void {
    const id = household._id;
    this.peopleService.setFamilyValue(household);
    this.router.navigate([appRouter.home.peopleMenu.houseHoldDetail, id]);
  }

  /**
   * <p>
   *  Route to the Branch Detail given branch details
   * </p>
   *
   * @param branch
   */
  backToBranchDetail(branch: any): void {
    const id = branch._id;
    this.peopleService.setBranchValue(branch);
    this.router.navigate([appRouter.home.peopleMenu.branchDetail]);
  }

  /**
   * <p>
   *   Get the updated parent values like branch and area for the households and individuals.
   * </p>
   */
  private updateParentValues() {
    this.peopleService.branchUpdated.subscribe((data) => {
      this.branch = data;
    });

    this.peopleService.areaUpdated.subscribe((data) => {
      this.area = data;
    });
  }

  /**
   * <p>
   *  Get the CHW Area details like families and individuals
   * for the area.
   * </p>
   *
   * @param id  - area id.
   */
  private getChwAreaDetails(id: string | null) {
    const startKey = '["' + id + '"]';
    const endKey = '["' + id + '", {}]';

    this.peopleService
      .getDocDetailsByParentId(encodeURIComponent(startKey), encodeURIComponent(endKey))
      .subscribe((res) => {
        res.rows.forEach((row: any) => {
          if (row.doc.type === 'clinic') {
            this.households.push(row.doc);
          }
          if (row.doc.type === 'person') {
            this.individuals.push(row.doc);
          }
        });
      });
  }

  /**
   * <p>
   *   Get Icons from the resources.
   * </p>
   */
  private getResources() {
    this.peopleService.getResources().subscribe((res: any) => {
      this.resources = res.resources;
      this.peopleService.getIcon(this.resources.area).subscribe((image: any) => {
        if (image) {
          this.areaIcon = base64Prefix + image.base64;
        }
      });
    });
  }
}
