<div class="container-fluid">
  <div class="font-size-24 font-weight-500 pl-3 pb-3">
    {{ 'LOCATION_TRACKING.HEADER' | translate }}
  </div>
  <div class="card location">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-xl-4 col-md-12 col-sm-12 search-height pr-0 pt-3">
        <div class="padding-value px-3">
          <div class="d-flex mb-4">
            <div class="ng-autocomplete">
              <ng-autocomplete class="auto-complete-input" [data]="searchArray" [searchKeyword]="keyword"
                placeholder="Select a user to start tracking..." (inputCleared)="inputCleared()"
                (selected)="selectEvent($event)" (inputChanged)="onChangeSearch()" (inputFocused)="onFocused($event)"
                [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
              </ng-template>
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
          <div class="font-size-14 font-weight-500 mb-2">{{ 'LOCATION_TRACKING.SELECT_DURATION' | translate }}</div>
          <div class="row px-3">
            <div class="col-6 px-1" *ngFor="let button of buttons; let i = index">
              <div (click)="onButtonClick(i, button)"
                [ngClass]="{ 'border-blue': i == selectedButton, 'border-disable': i !== selectedButton }"
                class="cursor-pointer mb-2 d-flex justify-content-center p-1 align-items-center border-radius-4 font-size-14 button-height">
                {{ button.name }}
              </div>
            </div>
          </div>
          <div class="py-2">
            <div class="font-size-14 font-weight-500 my-2">{{ 'LOCATION_TRACKING.SELECT_FORM' | translate }}</div>
            <select (change)="selectedForm(selectedValue)" [(ngModel)]="selectedValue"
              class="form-control select-arrow-custom" id="defaultLanguage">
              <option value="" readonly="true" hidden="true" selected>
                {{ 'LOCATION_TRACKING.ALL_FORMS' | translate }}
              </option>
              <option *ngFor="let form of formName" [ngValue]="form" value="form.doc.internalId">
                {{ form.doc.title }}
              </option>
            </select>
          </div>
          <div class="toggle-switch border-radius-22 d-flex cursor-pointer mt-4">
            <p class="switch-button m-0 w-50 p-2 text-center" [class.active]="toggleChecked" (click)="onSwitchValue()">
              {{ 'LOCATION_TRACKING.ROUTE_MAP' | translate }}
            </p>
            <p class="switch-button m-0 w-50 p-2 text-center" [class.active]="!toggleChecked" (click)="onSwitchValue()">
              {{ 'LOCATION_TRACKING.HEAT_MAP' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="map-outer col-md-12 col-xl-8 col-lg-8 col-sm 11 p-4">
        <div id="map"></div>
      </div>
    </div>
  </div>
</div>