<form [formGroup]="forgotPasswordForm" class="mx-auto">
  <div class="text-center">
    <h4 class="password-forgotten">{{ 'AUTH.FORGOT_PASSWORD' | translate }}</h4>
    <p class="note">{{ 'AUTH.NOTE' | translate }}</p>
  </div>
  <div class="form-group pb-2">
    <label class="label-input font-size-12 mb-0 ml-2">{{ 'AUTH.EMAIL_LABEL' | translate }}</label>
    <input type="text" class="form-control input-shadow" formControlName="email" />
  </div>
  <button
    type="button"
    [disabled]="!forgotPasswordForm.valid"
    (click)="routeToResetLinkPage()"
    class="btn btn-primary w-100 mt-1 auth-button"
  >
    {{ 'BUTTONS.RESET_LINK' | translate }}
  </button>
  <div class="pt-3">
    <span class="link-button cursor-pointer" (click)="backToLogin()">
      <img src="assets/images/back-arrow.svg" alt="febris-logo" />
      {{ 'AUTH.BACK_TO_LOGIN' | translate }}
    </span>
  </div>
</form>
