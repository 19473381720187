import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from 'src/app/services/app.service';
import { ToasterServices } from 'src/app/services/toasterService/toaster.service';
import appRouter from '../../constants/api-route-constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup = new FormGroup({});
  accessToken = '';
  email = '';

  constructor(
    private fb: FormBuilder,
    private appService: AppService,
    private router: Router,
    private toastr: ToasterServices,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.accessToken = params.token;
    });
  }

  ngOnInit(): void {
    this.initLoginForm();
    this.checkVerifyToken();
  }

  checkVerifyToken(): void {
    this.appService.verifyToken(this.accessToken).subscribe(
      (data) => {
        this.email = data.userDetails._id;
      },
      (error) => {
        this.toastr.error('Mail Expired');
        this.router.navigate([appRouter.auth.login]);
      }
    );
  }

  initLoginForm(): void {
    this.resetForm = this.fb.group(
      {
        password: ['', [Validators.required]],
        confirmPassword: [''],
      },
      { validator: this.MustMatch('password', 'confirmPassword') }
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.resetForm.controls;
  }

  // custom validator to check that two fields match
  MustMatch(controlName: string, matchingControlName: string): any {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  submit(): void {
    const data = {
      email: this.email,
      accessToken: this.accessToken,
      password: this.resetForm.value.password,
    };
    this.appService.setNewPassword(data).subscribe((value) => {
      this.toastr.success('Password successfully changed.');
      this.router.navigate([appRouter.auth.login]);
    });
  }

  backToLogin(): void {
    this.router.navigate([appRouter.auth.login]);
  }
}
