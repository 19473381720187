<section>
  <div [style.background-color]="headerData ? headerData.header_side_bar : 'white'"
    class="header position-fixed w-100 px-4 d-flex justify-content-between align-items-center">
    <img class="header-logo" src="{{ imagePath ? imagePath : 'assets/images/febris-logo.svg' }}" alt="febris-logo" />
    <div class="d-flex mr-4">
      <!-- <img class="pr-3" src="assets/images/bell-icon.svg" alt="bell-icon" /> -->
      <div class="d-flex align-items-center">
        <!-- <img class="pr-4" src="assets/images/profile-img.svg" alt="profile-icon" /> -->
        <div ngbDropdown>
          <div ngbDropdownToggle class="mr-2 cursor-pointer">
            <div class="font-size-10 user-role">{{userRole}}</div>
            <div class="d-flex align-items-center">
              <div class="font-size-12">{{userName}}</div>
              <img class="ml-2" src="assets/images/arrow-down.svg" alt="down" />
            </div>
          </div>
          <div class="dropdown-menu dropdown-menu-right mr-2" aria-labelledby="dropdownBasic1" ngbDropdownMenu>
            <button class="dropdown-item" (click)="logout()">Logout</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>