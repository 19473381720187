<div class="p-3 supervisor-detail">
  <div class="d-flex cursor-pointer mb-3" (click)="backToBranchDetail(branch)">
    <img src="assets/images/back-button.svg" />
    <span class="ml-2 font-size-14 link-button">
      {{ 'BUTTONS.BACK' | translate }}
    </span>
  </div>
  <div class="d-flex justify-content-between pb-3 align-items-center mr-4">
    <div class="d-flex align-items-center">
      <img width="60" height="60" src="{{ areaIcon }}" />
      <div class="pl-3">
        <div class="font-size-20 font-weight-500 text-capitalize">{{ area.name }}</div>
        <div class="font-weight-400 link-button text-capitalize">{{ branch.name }}</div>
      </div>
    </div>
    <div>
      <!-- <button class="btn btn-outline-secondary mr-2">
        <span class="font-size-14">
          {{ 'BUTTONS.EDIT_AREA' | translate }}
        </span>
      </button> -->
    </div>
  </div>
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1" class="tab-first tabs border-radius-4">
      <a ngbNavLink class="font-size-14 font-weight-500">
        {{ 'PEOPLE.HOUSEHOLDS' | translate }}
      </a>
      <ng-template ngbNavContent>
        <div class="overflow-auto p-1 set-height-width">
          <div *ngFor="let household of households; let i = index" (click)="routeToHouseHoldDetail(household)"
            class="box-width text-capitalize font-weight-500 cursor-pointer font-size-14 border p-3 mt-1 border-radius-4">
            {{ household.contact.name }}
          </div>
          <div *ngIf="households.length === 0"
            class="box-width font-weight-400 cursor-pointer font-size-14 border p-3 mt-2 border-radius-4">
            {{ 'PEOPLE.NO_HOUSEHOLDS_FOUND' | translate }}
          </div>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="2" class="tabs">
      <a ngbNavLink class="font-size-14 font-weight-500 border-right-0">
        {{ 'LABELS.PRIMARY_CONTACT' | translate }}
      </a>
      <ng-template ngbNavContent>
        <div class="overflow-auto p-1 set-height-width">
          <div *ngFor="let individual of individuals; let i = index"
            class="font-weight-500 text-capitalize  justify-content-between d-flex font-size-14 border p-3 mt-2 border-radius-4">
            <div>
              {{ individual.name || individual.person.name }}
            </div>
            <div *ngIf="individual.primary && individual.primary === true" class="d-flex align-items-center">
              <img class="mr-1" src="assets/images/star-icon.svg" />
              <div class="primary-contact font-weight-400 mr-2">
                {{ 'LABELS.PRIMARY_CONTACT' | translate }}</div>
            </div>
          </div>
          <div *ngIf="individuals.length === 0" class="font-weight-400 font-size-14 border p-3 mt-1 border-radius-4">
            {{ 'PEOPLE.NO_INDIVIDUALS_FOUND' | translate }}
          </div>
        </div>
      </ng-template>
    </li>

    <!-- <li [ngbNavItem]="3" class="tabs border-radius-4">
      <a ngbNavLink class="font-size-14 font-weight-500">
        {{ 'PEOPLE.REPORTS' | translate }}
      </a>
      <ng-template ngbNavContent>
        <div class="font-weight-500 font-size-14 border p-3 mt-3 border-radius-4">Adiya Musosi Area</div>
      </ng-template>
    </li> -->
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>