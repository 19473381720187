import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// import { IModalOption } from 'src/app/application-settings/outgoing-messages/outgoing-messages.component';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { base64Prefix } from '../../constants/constants';
import { ExportToCsv } from 'export-to-csv';
import * as moment from 'moment';
declare const require: any;
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  data: any;
  arrayId: any;
  skip = 0;
  resourcesDetails: any;
  forms: any;
  formImageDetails: any = new Map();
  formTitle: any = [];
  selectedIndex = 0;
  placeList: any = [];
  startDate = 0;
  endDate = 0;
  formsFields: any = [];
  reviewData: any = [];
  bulkDoc: any = [];
  formSelect: any;
  placeSelect: any;
  startDateSelect: any;
  endDateSelect: any;
  formFilterId: any = [];
  placeFilterId: any = [];
  dateFilterId: any = [];
  formData: any;
  isCorrect = false;
  isError = false;
  formXmlDetail: any = new Map();
  isScrollDisable = false;
  formSelected = false;
  placeSelected = false;
  // @ViewChild('modalContent') modalTemplate: any;
  // content: any;
  // isModalVisible = false;
  // roleFormGroup: FormGroup;
  // roleForm: any = {
  //   role: '',
  //   translationKey: '',
  // };
  // modalOptions: IModalOption = {
  //   centered: true,
  //   backdropClass: 'modal-black-backdrop',
  //   backdrop: 'static',
  //   keyboard: false,
  //   size: 'sm',
  // };
  searchFamily: any = [];
  selectedFamily: any;
  searchFilter: any = {
    startDate: '',
    endDate: '',
  };
  selectedFormIds: any[] = [];
  selectedAreaIds: any[] = [];
  form: FormGroup | undefined;
  currentDate = '';
  showFormDownDrop = false;
  showPlaceDownDrop = false;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private reportsService: ReportsService
  ) {
    this.form = this.formBuilder.group({
      formTitle: this.formBuilder.array([], [Validators.required]),
    });
    this.currentDate = Date.now().toString();
  }

  ngOnInit(): void {
    this.getResources();
    this.getPlaceDetails();
  }

  getResources(): void {
    // this.reportsService.getResources().subscribe((resource) => {
    //   this.resourcesDetails = resource.resources;
    //   this.reportsService.getForms().subscribe((forms) => {
    //     this.forms = forms;
    //     if (this.forms) {
    //       this.forms.forEach((element: any) => {
    //         const form: string[] = element.split('.');
    //         this.reportsService.getFormDetails(form[0]).subscribe((formDet) => {
    //           this.formImageDetails['form:' + form[0]] = this.resourcesDetails[formDet.icon];
    //           this.formTitle.push({ id: 'form:' + form[0], name: formDet.title, checked: false });
    //         });
    //       });
    //     }
    let XmlDetail: any;
    this.reportsService.getForms().subscribe((res) => {
      this.formData = res.rows;
      if (this.formData) {
        this.reportsService.getResources().subscribe((val) => {
          this.formData?.map((data: any) => {
            if (val.resources[data.doc.icon]) {
              //if (data.doc.icon === val.resources[data.doc.icon].split('.')[0]) {
              this.reportsService.getFormImageIcon(val.resources[data.doc.icon]).subscribe((img) => {
                this.formImageDetails[data.doc._id] = img.base64;
                this.formTitle.push({ id: data.doc._id, name: data.doc.title, checked: false });
                // data.doc.img = base64Prefix + img.base64;
              });
            }

            this.reportsService.getFormXmlDetail(data.id).subscribe((xml) => {
              var parseString = require('xml2js').parseString;
              parseString(xml, function (err: any, result: any) {
                XmlDetail = result;
              });
              this.formXmlDetail[data.id] = XmlDetail;
            });
            //}
          });
          this.getReportDetails();
        });
      }
    });
  }

  /** To export the report*/
  onExportClick(): void {
    const flatten = require('flat');
    let docs: any = [];
    let formName: any;
    let columnNames: any = [];
    let formDetails: any;
    let documentDetails: any;
    let formKey: any = '';
    let formValue: any;
    let finalDoc: any = [];

    docs = this.searchFamily.map((val: any) => {
      const form: any = val.doc.form.split(':');
      formName = form[1];
      delete val.doc._id;
      delete val.doc._rev;
      delete val.doc.reported_date;
      delete val.doc.version;
      delete val.doc['xmlns:odk'];
      delete val.doc['xmlns:jr'];
      delete val.doc['xmlns:h'];
      delete val.doc['xmlns:ev'];
      delete val.doc['xmlns:orx'];
      delete val.doc['xmlns:xsd'];
      delete val.doc['isCompleted'];
      delete val.doc['type'];
      delete val.doc._attachments;

      if (val.doc[formName]) {
        delete val.doc[formName]['xmlns:odk'];
        delete val.doc[formName]['xmlns:jr'];
        delete val.doc[formName]['xmlns:h'];
        delete val.doc[formName]['xmlns:ev'];
        delete val.doc[formName]['xmlns:orx'];
        delete val.doc[formName]['xmlns:xsd'];
      }
      const formObject =
        this.formXmlDetail[val.doc.form] === undefined ? '' : this.formXmlDetail[val.doc.form]['h:html']['h:body'];
      formDetails = Object.keys(flatten(formObject)).map((key: string) => ({
        key,
        value: flatten(formObject)[key],
      }));

      documentDetails = Object.keys(flatten(val)).map((key: string) => ({
        key,
        value: flatten(val)[key],
      }));

      finalDoc = [];
      formDetails.forEach((form: any) => {
        documentDetails.forEach((doc: any) => {
          if (doc['key'] === 'name' || doc['key'] === 'sub.0') {
            finalDoc[doc['key'] === 'sub.0' ? 'Form Name' : doc['key']] = doc['value'];
          }
          if (
            form['value'] === '/data/' + doc['key'].replaceAll('.', '/').replaceAll('doc/', '') ||
            form['value'] ===
              '/data/' + doc['key'].replace(formName.concat('.'), '').replaceAll('.', '/').replaceAll('doc/', '') ||
            form['value'] === '/' + doc['key'].replaceAll('.', '/').replaceAll('doc/', '')
          ) {
            formKey = form['key'].replace('$.ref', '').concat('label.0');
            formValue = doc['value'];
          }
          if (form['key'] === formKey || form['key'] === formKey.concat('._')) {
            finalDoc[form['value'].replaceAll(',', '')] = formValue.replaceAll(',', '');
            formKey = '';
          }
        });
      });
      return finalDoc;
    });

    docs.forEach((doc: any) => {
      delete doc.icons;
      columnNames = columnNames.concat(Object.keys(doc));
    });

    columnNames = columnNames.filter((item: string, index: number) => columnNames.indexOf(item) === index);

    docs.forEach((doc: any) => {
      columnNames.forEach((header: any) => {
        if (!Object.keys(doc).includes(header)) {
          doc[header] = '';
        }
      });
    });

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      filename: 'Report',
      headers: columnNames,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(docs);
  }

  /** To get the start date */
  onclickStartDate(event: any): void {
    const date = new Date(event.target.value);
    this.startDateSelect = '[' + date.getTime() + ']';
    this.filterReportsByDate(this.startDateSelect, this.endDateSelect);
  }

  /** To get the end date and corresponding filter method */
  onclickEndDate(event: any): void {
    let endDate = moment(event.target.value);
    endDate = endDate.endOf('day');
    const date = new Date(endDate.format());
    this.endDateSelect = '[' + date.getTime() + ']';
    this.filterReportsByDate(this.startDateSelect, this.endDateSelect);
  }

  openVerticallyCentered(content: any) {
    this.modalService.open(content, { centered: true });
  }

  /** To review the report if the report is correct */
  infoCorrect(): void {
    if (this.selectedFamily.verified) {
      this.isCorrect = false;
      this.review(this.isCorrect, 'error');
    } else {
      this.isCorrect = true;
      this.review(this.isCorrect, 'review');
    }

    this.modalService.dismissAll();
  }

  /** To review the report*/
  review(validate: boolean, review: String): void {
    this.reportsService.getViewReportDetail(this.selectedFamily.id).subscribe((reportData) => {
      const date = new Date();
      this.reviewData = [];
      reportData.verified = validate;
      reportData.verified_date = date.getTime();
      this.reviewData.push(reportData);
      if (review === 'error') {
        delete this.reviewData[0].verified;
        delete this.reviewData[0].verified_date;
      }
      this.bulkDoc = {
        newEdits: true,
        docs: this.reviewData,
      };
      this.reportsService.reviewReport(this.bulkDoc).subscribe((response) => {
        if (response[0].ok === true) {
          this.clearData();
          this.reportsService.getTaskReports(0).subscribe((data) => {
            this.data = data.rows;
            this.getAllDocs(this.data);
          });
        }
      });
    });
  }

  /** To review the report if it has the error */
  infoError(): void {
    if (this.selectedFamily.error) {
      this.isError = true;
      this.review(this.isError, 'error');
    } else {
      this.isError = false;
      this.review(this.isError, 'review');
    }
    this.modalService.dismissAll();
  }

  /** display the selected family */
  setSelectedFamily(family: any, index: number): void {
    this.selectedIndex = index;
    let formDetails: any;
    let documentDetails: any;
    let formKey = '';
    let formValue = '';

    if (family) {
      this.selectedFamily = family;
      const flatten = require('flat');
      const form: any = this.selectedFamily.doc.form.split(':');
      const formName: any = form[1];
      delete this.selectedFamily.doc._id;
      delete this.selectedFamily.doc._rev;
      delete this.selectedFamily.doc.reported_date;
      delete this.selectedFamily.doc.version;
      delete this.selectedFamily.doc['xmlns:odk'];
      delete this.selectedFamily.doc['xmlns:jr'];
      delete this.selectedFamily.doc['xmlns:h'];
      delete this.selectedFamily.doc['xmlns:ev'];
      delete this.selectedFamily.doc['xmlns:orx'];
      delete this.selectedFamily.doc['xmlns:xsd'];
      delete this.selectedFamily.doc['isCompleted'];
      delete this.selectedFamily.doc['type'];
      delete this.selectedFamily.doc._attachments;
      if (this.selectedFamily.doc[formName]) {
        delete this.selectedFamily.doc[formName]['xmlns:odk'];
        delete this.selectedFamily.doc[formName]['xmlns:jr'];
        delete this.selectedFamily.doc[formName]['xmlns:h'];
        delete this.selectedFamily.doc[formName]['xmlns:ev'];
        delete this.selectedFamily.doc[formName]['xmlns:orx'];
        delete this.selectedFamily.doc[formName]['xmlns:xsd'];
      }
      const flatObject = flatten(this.selectedFamily.doc);
      const formObject = flatten(this.formXmlDetail[family.doc.form]['h:html']['h:body']);
      formDetails = Object.keys(formObject).map((key: string) => ({
        key,
        value: formObject[key],
      }));
      documentDetails = Object.keys(flatObject).map((key: string) => ({
        key,
        value: flatObject[key],
      }));
      this.formsFields = [];
      formDetails.forEach((form: any) => {
        documentDetails.forEach((doc: any) => {
          if (
            form['value'].replaceAll('/data/', '') === doc['key'].replaceAll('.', '/') ||
            form['value'] === '/data/' + doc['key'].replace(formName.concat('.'), '').replaceAll('.', '/') ||
            form['value'] === '/' + doc['key'].replaceAll('.', '/')
          ) {
            formKey = form['key'].replace('$.ref', '').concat('label.0');
            formValue = doc['value'];
          }
          if (form['key'] === formKey || form['key'] === formKey.concat('._')) {
            this.formsFields.push({
              key: form['value'],
              value: formValue,
            });
            formKey = '';
          }
        });
      });
    }
  }

  /** To get the report details initially after each scroll */
  getReportDetails(): void {
    this.reportsService.getTaskReports(this.skip).subscribe((data) => {
      this.data = data.rows;
      this.skip = this.skip + 10;
      this.getAllDocs(this.data);
    });
  }

  /** filter the report by form */
  filterReportsByForm(): void {
    const keys: string[] = [];
    this.selectedFormIds.forEach((id: any) => {
      keys.push('["' + id + '"]');
    });
    if (!keys || !keys.length) {
      this.formFilterId = [];
      return;
    }
    const key = '[' + keys.toString() + ']';
    this.reportsService.getReportsByForm(encodeURIComponent(key)).subscribe((res) => {
      this.data = res.rows;
      this.formFilterId = res.rows.map((val: any) => val.id);
    });
  }

  /** filter the reports by place */
  filterReportsByPlace(): void {
    const keys: string[] = [];
    this.selectedAreaIds.forEach((id: any) => {
      keys.push('["' + id + '"]');
    });
    if (!keys || !keys.length) {
      this.placeFilterId = [];
      return;
    }
    const key = '[' + keys.toString() + ']';
    console.log('key', key);

    this.reportsService.getReportsByPlace(encodeURIComponent(key)).subscribe((res) => {
      this.data = res.rows;
      this.placeFilterId = res.rows.map((val: any) => val.id);
    });
  }

  /** filter the reports by date */
  filterReportsByDate(startDate: any, endDate: any): void {
    console.log('startDate', startDate);
    console.log('endDate', endDate);
    if (startDate && endDate) {
      this.reportsService
        .getReportsByDate(encodeURIComponent(startDate), encodeURIComponent(endDate))
        .subscribe((res) => {
          this.data = res.rows;
          this.dateFilterId = res.rows.map((val: any) => val.id);
        });
    }
  }

  /** To get combination of filter */
  applyFilter(): void {
    let id = [];
    this.clearData();
    if (this.formSelected && this.placeSelected && this.searchFilter.startDate && this.searchFilter.endDate) {
      const data = [this.formFilterId, this.placeFilterId, this.dateFilterId];
      id = data.reduce((a: any, b: any) => a.filter((c: any) => b.includes(c)));
      this.getDocs(id);
    } else if (this.formSelected && this.placeSelected && !this.searchFilter.startDate && !this.searchFilter.endDate) {
      id = this.formFilterId.filter((a: any) => this.placeFilterId.includes(a));
      this.getDocs(id);
    } else if (!this.formSelected && this.placeSelected && this.searchFilter.startDate && this.searchFilter.endDate) {
      id = this.placeFilterId.filter((a: any) => this.dateFilterId.includes(a));
      this.getDocs(id);
    } else if (this.formSelected && !this.placeSelected && this.searchFilter.startDate && this.searchFilter.endDate) {
      id = this.formFilterId.filter((a: any) => this.dateFilterId.includes(a));
      this.getDocs(id);
    } else if (this.formSelected) {
      this.getDocs(this.formFilterId);
    } else if (this.placeSelected) {
      console.log('coming place');
      this.getDocs(this.placeFilterId);
    } else if (this.searchFilter.startDate && this.searchFilter.endDate) {
      this.getDocs(this.dateFilterId);
    }

    this.isScrollDisable = true;
  }

  /** search the report */
  filterReportBySearch(event: any): void {
    if (event.keyCode === 13) {
      this.clearData();
      if (event.target.value === '') {
        this.reportsService.getTaskReports(0).subscribe((data) => {
          this.data = data.rows;
          this.getAllDocs(this.data);
        });
      } else {
        const startKey = '["' + event.target.value + '"]';
        const endKey = '["' + event.target.value + '\ufff0"]';
        this.reportsService
          .getReportsBySearch(encodeURIComponent(startKey), encodeURIComponent(endKey))
          .subscribe((res) => {
            this.data = res.rows;
            this.getAllDocs(this.data);
          });
      }
    }
    this.isScrollDisable = true;
  }

  /** get place list in the drop down */
  getPlaceDetails(): void {
    const branchKey = '["district_hospital"]';
    const areaKey = '["health_center"]';
    this.reportsService.getPlaceList(encodeURIComponent(branchKey)).subscribe((branch) => {
      this.reportsService.getPlaceList(encodeURIComponent(areaKey)).subscribe((area) => {
        branch.rows.forEach((branchDetail: any) => {
          const areas: any[] = [];
          area.rows.forEach((areaDetail: any) => {
            if (branchDetail.id === areaDetail.doc.parent._id) {
              areas.push({
                id: areaDetail.id,
                name: areaDetail.doc.name,
                checked: false,
              });
            }
          });
          this.placeList.push({
            id: branchDetail.id,
            name: branchDetail.doc.name,
            subOptions: areas,
            checked: false,
          });
        });
      });
    });
  }

  /**
   * To act on form checked
   *
   * @param event - checked or not
   */
  onFormTypesChange(event: Event): void {
    this.selectedFormIds = this.formTitle.filter((form: any) => form.checked).map((formName: any) => formName.id);
    this.filterReportsByForm();
  }

  /**
   * To modify the form title
   *
   * @param event - checked or not
   */
  modifyFormTitleOption(event: boolean): void {
    this.formTitle.forEach((element: any) => {
      element.checked = event;
    });
    this.selectedFormIds = this.formTitle.filter((form: any) => form.checked).map((formName: any) => formName.id);
    this.filterReportsByForm();
  }

  /**
   * To act on place list checked
   *
   * @param event - checked or not
   */
  onPlaceListChange(event: Event): void {
    if (this.placeList.find((place: any) => place.id === event)) {
      const placeForm = this.placeList.find((place: any) => place.id === event);
      if (placeForm.subOptions) {
        placeForm.subOptions.forEach((area: any) => {
          area.checked = placeForm.checked;
        });
      }
    }
    this.selectedAreaIds = [];
    this.placeList.forEach((element: any) => {
      if (element.checked) {
        this.selectedAreaIds.push(element.id);
      }
      this.selectedAreaIds = this.selectedAreaIds.concat(
        element.subOptions.filter((area: any) => area.checked).map((area: any) => area.id)
      );
    });
    this.filterReportsByPlace();
  }

  /**
   * To modify the place list
   *
   * @param event - check or not
   */
  modifyPlaceListOption(event: boolean): void {
    this.placeList.forEach((element: any) => {
      element.checked = event;
      if (element.subOptions) {
        element.subOptions.forEach((area: any) => {
          area.checked = event;
        });
      }
    });
    this.selectedAreaIds = [];
    this.placeList.forEach((element: any) => {
      if (element.checked) {
        this.selectedAreaIds.push(element.id);
      }
      this.selectedAreaIds = this.selectedAreaIds.concat(
        element.subOptions.filter((area: any) => area.checked).map((area: any) => area.id)
      );
    });
    this.filterReportsByForm();
  }

  /**
   * To reset filter
   */
  resetCLickForm(): void {
    this.searchFilter.startDate = '';
    this.searchFilter.endDate = '';
    this.modifyFormTitleOption(false);
    this.modifyPlaceListOption(false);
    this.selectedFormIds = [];
    this.selectedAreaIds = [];
    this.skip = 0;
    this.clearData();
    this.getReportDetails();
    this.isScrollDisable = false;
  }

  /**
   * To get the count of the filter
   *
   * @returns- filter count
   */
  getCountOfFilter(): number {
    let count = 0;
    if (this.searchFilter.startDate) {
      count++;
    }
    if (this.searchFilter.endDate) {
      count++;
    }
    this.formSelected = false;
    this.formTitle.forEach((element: any) => {
      if (element?.checked) {
        this.formSelected = true;
      }
    });
    if (this.formSelected) {
      count++;
    }
    this.placeSelected = false;
    this.placeList.forEach((element: any) => {
      if (element?.checked) {
        this.placeSelected = true;
      }
      if (element?.subOptions) {
        element.subOptions.forEach((area: any) => {
          if (area?.checked) {
            this.placeSelected = true;
          }
        });
      }
    });
    if (this.placeSelected) {
      count++;
    }
    return count;
  }

  /**
   * To get current date as string
   *
   * @returns - date as string
   */
  getCurrentDate(): string {
    return new Date().toDateString();
  }

  /**
   * To get the place holder for the place lis
   *
   * @returns placeholder
   */
  getPlaceListPlaceHolder(): string {
    let count = 0;
    this.placeList.forEach((element: any) => {
      if (element?.subOptions) {
        element.subOptions.forEach((area: any) => {
          if (area.checked) {
            count++;
          }
        });
      }
    });
    return count ? count + ' Area Selected' : 'All Places';
  }

  getFormsPlaceholder(): string {
    return this.selectedFormIds?.length
      ? this.selectedFormIds?.length?.toString() + ' Forms Selected' || ''
      : 'All forms';
  }

  /** get all the detailed report document */
  private getAllDocs(docs: any): void {
    this.arrayId = [];
    if (docs) {
      this.arrayId = this.data.map((val: any) => val.id);
      this.getDocs(this.arrayId);
    }
  }

  /** To modify the time format */
  private timeSince(date: any) {
    const currentDate: any = new Date();
    const diff: any = currentDate - date;
    const seconds = Math.floor(diff / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + ' years ago';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + ' months ago';
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + ' days ago';
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' hours ago';
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' minutes ago';
    }
    return Math.floor(seconds) + ' seconds ago';
  }

  /** get all the detailed documents */
  private getDocs(ids: any): void {
    const reportedDate = 'reported_date';
    this.reportsService.getAllDocByReportId({ keys: ids }).subscribe((obj) => {
      obj.rows.map((value: any) => {
        // let icon: any;
        // const formImage =
        //   this.formImageDetails[value.doc.form] !== undefined ? this.formImageDetails[value.doc.form] : 'mute.png';
        // this.reportsService.getFormImageIcon(formImage).subscribe((image) => {
        //   if (image) {
        //     icon = base64Prefix + image.base64;
        //   }
        let formsName = this.formTitle
          .filter((form: any) => form.id === value.doc.form)
          .map((formName: any) => formName.name);
        this.searchFamily.push({
          id: value.id,
          name: value.doc.displayName,
          icons: this.formImageDetails[value.doc.form]
            ? base64Prefix + this.formImageDetails[value.doc.form]
            : 'assets/images/default.svg',
          sub: formsName.length > 0 ? formsName : 'Form Unavailable',
          days: this.timeSince(new Date(value.doc[reportedDate])),
          doc: value.doc,
          verified: value.doc.verified === undefined ? false : value.doc.verified,
          error: !(value.doc.verified === undefined ? true : value.doc.verified),
        });
      });
      this.selectedFamily = this.searchFamily[0];
      this.setSelectedFamily(this.selectedFamily, 0);
    });
  }

  /** To clear the data */
  private clearData(): void {
    this.selectedFamily = [];
    this.searchFamily = [];
    this.data = [];
    this.formsFields = [];
  }
}
