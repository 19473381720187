import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { FormModalComponent } from './form-modal/form-modal.component';
import { LoaderComponent } from './loader/loader.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { PaginationComponent } from './pagination/pagination.component';
import { JsonRuleEditorModalComponent } from './json-rule-editor-modal/json-rule-editor-modal.component';
import { FormPreviewComponent } from './form-preview/form-preview.component';

const components = [
  PaginationComponent,
  FormModalComponent,
  ConfirmModalComponent,
  MultiSelectDropdownComponent,
  LoaderComponent,
  JsonRuleEditorModalComponent,
  FormPreviewComponent,
];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule],
  exports: [...components],
})
export class SharedModule {}
