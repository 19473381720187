<section>
  <div
    class="d-flex justify-content-between align-items-center w-100 form-inline h-auto pl-2 pr-2 border-top row pt-lg-0 pt-3 mr-0 m-auto"
    *ngIf="totalRecords && totalRecords > limit"
  >
    <div class="col-lg-4 col-md-12 col-12 opacity-per-40 text-lg-left text-center">
      {{ 'APPLICATION_SETTINGS.PAGINATION.SHOWING' | translate }} {{ start + 1 }} - {{ offset }}
      {{ 'APPLICATION_SETTINGS.PAGINATION.OF' | translate }} {{ totalRecords }}
    </div>
    <div class="col-lg-8 col-md-12 col-12 d-flex justify-content-lg-end justify-content-center form-inline">
      <div class="d-flex text-left ml-lg-5 ml-0">
        <nav class="mt-3">
          <ul class="pagination d-flex justify-content-between align-items-center">
            <li
              class="page-item"
              (click)="onClickPage(currentPage - 1)"
              [ngClass]="currentPage === 1 ? 'disabled' : ''"
            >
              <a class="page-link width-32 height-32" href="javascript:void(0);"> </a>
            </li>
            <li class="page-item" class="disabled" *ngIf="!(currentPage + range < totalPage) && showEllipses">
              <a class="page-link" href="javascript:void(0);" (click)="onClickPage(1)">{{ startPage }}</a>
            </li>
            <li class="page-item" class="disabled" *ngIf="!(currentPage + range < totalPage) && showEllipses">
              <a class="page-link" href="javascript:void(0);">...</a>
            </li>
            <li
              class="page-item"
              [ngClass]="{ active: currentPage === item }"
              *ngFor="let item of pages"
              (click)="onClickPage(item)"
            >
              <a class="page-link height-32" href="javascript:void(0);">{{ item }}</a>
            </li>
            <li class="page-item" class="disabled" *ngIf="currentPage + range < totalPage && showEllipses">
              <a class="page-link" href="javascript:void(0);">...</a>
            </li>
            <li class="page-item" class="disabled" *ngIf="currentPage + range < totalPage && showEllipses">
              <a class="page-link" href="javascript:void(0);" (click)="onClickPage(totalPage)">{{ totalPage }}</a>
            </li>
            <li
              class="page-item"
              (click)="onClickPage(currentPage + 1)"
              [ngClass]="totalPage === currentPage ? 'disabled' : ''"
            >
              <a class="page-link width-32 height-32" href="javascript:void(0);"></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</section>
