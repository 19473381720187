<div class="d-flex justify-content-between pb-3 mt-3 align-items-center mr-4">
  <div class="d-flex pl-3 align-items-center">
    <img width="60" height="60" src="{{ branchIcon }}" />
    <div class="pl-3">
      <div class="font-size-20 text-capitalize font-weight-500">{{ branch.name }}</div>
    </div>
  </div>
  <div class="">
    <button class="btn btn-outline-secondary mr-2" (click)="editBranchModal()">
      <span class="font-size-14">
        {{ 'BUTTONS.EDIT_BRANCH' | translate }}
      </span>
    </button>
  </div>
</div>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs ml-3">
  <li [ngbNavItem]="1" class="tab-first tabs border-radius-4">
    <a ngbNavLink class="font-size-14 font-weight-500">
      {{ 'PEOPLE.SUPERVISORS' | translate }}
    </a>
    <ng-template ngbNavContent>
      <div class="overflow-auto p-1 set-height-width">
        <div *ngIf="primaryContact._id !== null && primaryContact._id !== undefined"
          (click)="routeToDetailPage(primaryContact)"
          class="box-width primaryContact text-capitalize d-flex font-weight-500 justify-content-between cursor-pointer mx-3 font-size-14 border p-3 mt-2 border-radius-4">
          <div>
            {{ primaryContact.name }}
          </div>
          <div class="d-flex align-items-center">
            <img class="mr-1" src="assets/images/star-icon.svg" />
            <div class="primary-contact font-weight-400 mr-2">
              {{ 'LABELS.PRIMARY_CONTACT' | translate }}
            </div>
          </div>
        </div>
        <div *ngFor="let supervisorDetail of supervisorDetails; let i = index"
          (click)="routeToDetailPage(supervisorDetail)"
          class="box-width text-capitalize font-weight-500 cursor-pointer mx-3 font-size-14 border p-3 mt-1 border-radius-4">
          {{ supervisorDetail.person_name || supervisorDetail.name }}
        </div>
        <div *ngIf="!primaryContact._id && supervisorDetails.length === 0"
          class="box-width font-weight-400 cursor-pointer mx-3 font-size-14 border p-3 mt-2 border-radius-4">
          {{ 'PEOPLE.NO_PERSONS_FOUND' | translate }}
        </div>
      </div>
    </ng-template>
  </li>

  <li [ngbNavItem]="2" class="tab-middle tabs">
    <a ngbNavLink class="font-size-14 font-weight-500 border-right-0">
      {{ 'PEOPLE.CHW_AREA' | translate }}
    </a>
    <ng-template ngbNavContent>
      <div class="overflow-auto p-1 set-height-width">
        <div *ngFor="let chwAreaDetail of chwAreaDetails; let i = index" (click)="routeToChwDetailPage(chwAreaDetail)"
          class="font-weight-500 text-capitalize mx-3 font-size-14 border p-3 mt-2 border-radius-4">
          {{ chwAreaDetail.name }}
        </div>
        <div *ngIf="chwAreaDetails.length === 0"
          class="font-weight-400 mx-3 font-size-14 border p-3 mt-2 border-radius-4">
          {{ 'PEOPLE.NO_AREAS_FOUND' | translate }}
        </div>
      </div>
    </ng-template>
  </li>

  <li [ngbNavItem]="3" class="tabs border-curve">
    <a ngbNavLink class="font-size-14 font-weight-500">
      {{ 'PEOPLE.INDIVIDUAL_USERS' | translate }}
    </a>
    <ng-template ngbNavContent>
      <div class="overflow-auto p-1 set-height-width" infiniteScroll [infiniteScrollDistance]="3"
        [infiniteScrollThrottle]="30" (scrolled)="getIndividualUser()" [scrollWindow]="false">
        <div *ngFor="let user of individualUsers; let i = index"
          class="font-weight-500 text-capitalize mx-3 font-size-14 border p-3 mt-1 border-radius-4">
          {{ user.person_name || user.person.name }}
        </div>
        <div *ngIf="!individualUsers.length" class="font-weight-400 mx-3 font-size-14 border p-3 mt-2 border-radius-4">
          {{ 'PEOPLE.NO_INDIVIDUAL_USERS_FOUND' | translate }}
        </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>