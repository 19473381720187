<div class="main-container container-fluid m-auto">
  <div class="d-flex justify-content-between pb-3">
    <div class="font-size-24 font-weight-500 pl-3">
      {{ 'REPORTS.HEADER' | translate }}
    </div>
    <div class="d-flex">
      <button class="btn ml-2 button-blue button-spacing btn-light font-size-14" (click)="onExportClick()">
        <img class="pb-1 pr-1" src="assets/images/download-icon.svg" />
        {{ 'BUTTONS.EXPORT' | translate }}
      </button>
    </div>
  </div>

  <div class="card col-12">
    <div class="row justify-content-between">
      <div class="left-container search-space border-right-line col-lg-4 col-md-5 col-sm-12 col-12 pl-3 pr-2">
        <div class="d-flex mb-3 w-100">
          <div class="position-relative w-100">
            <input type="text" class="form-control w-100 pr-5" placeholder="Search.."
              (keyup)="filterReportBySearch($event)" />
            <img class="pb-1 pr-1 search-icon position-absolute" src="assets/images/search-icon.svg" />
          </div>
          <div ngbDropdown #filterDropdown="ngbDropdown">
            <button ngbDropdownToggle class="btn btn-outline-secondary  ml-2 button-blue button-spacing font-size-14">
              <img class="pb-1 pr-1" src="assets/images/filter-icon.svg" *ngIf="!getCountOfFilter()" />
              <span *ngIf="getCountOfFilter()"
                class="mr-2 bg-secondary text-white d-inline-block border-secondary rounded-circle count-label">
                <div>
                  {{ getCountOfFilter() }}
                </div>
              </span>
              {{ 'BUTTONS.FILTER' | translate }}
            </button>
            <div class="dropdown-menu mr-2" aria-labelledby="dropdownBasic1" ngbDropdownMenu>
              <app-multi-select-dropdown [options]="formTitle" [placeHolder]="getFormsPlaceholder()"
                [showDropDown]="showFormDownDrop" (checkInputChange)="onFormTypesChange($event)"
                (modifyOptions)="modifyFormTitleOption($event)" (mouseleave)="showFormDownDrop = false"
                (dropDownOptionChange)="showFormDownDrop = !showFormDownDrop"></app-multi-select-dropdown>
              <app-multi-select-dropdown [options]="placeList" [placeHolder]="getPlaceListPlaceHolder()"
                (checkInputChange)="onPlaceListChange($event)" (modifyOptions)="modifyPlaceListOption($event)"
                [showDropDown]="showPlaceDownDrop" (mouseleave)="showPlaceDownDrop = false"
                (dropDownOptionChange)="showPlaceDownDrop = !showFormDownDrop"></app-multi-select-dropdown>
              <div class="filter position-relative mt-2">
                <label class="position-absolute px-1">{{ 'REPORTS.START_DATE' | translate }}</label>
                <input class="form-control app-input position-relative" [(ngModel)]="searchFilter.startDate"
                  max="{{ currentDate | date: 'yyyy-MM-dd' }}" type="date" (change)="onclickStartDate($event)" />
              </div>
              <div class="filter position-relative mt-2">
                <label class="position-absolute px-1">{{ 'REPORTS.END_DATE' | translate }}</label>
                <input class="form-control app-input position-relative" [(ngModel)]="searchFilter.endDate"
                  min="{{ searchFilter.startDate | date: 'yyyy-MM-dd' }}" max="{{ currentDate | date: 'yyyy-MM-dd' }}"
                  type="date" [disabled]="!searchFilter.startDate" (change)="onclickEndDate($event)" />
              </div>
              <div class="w-100 d-flex justify-content-around mt-2">
                <button class="btn ml-2 button-blue button-spacing btn-light font-size-14"
                  (click)="resetCLickForm(); filterDropdown.close()">
                  {{ 'BUTTONS.RESET' | translate }}
                </button>
                <button class="btn ml-2 button-blue button-spacing btn-light font-size-14"
                  (click)="applyFilter(); filterDropdown.close()">
                  {{ 'BUTTONS.APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="search-height overflow-auto" infiniteScroll [infiniteScrollDistance]="3"
          [infiniteScrollThrottle]="30" (scrolled)="getReportDetails()" [scrollWindow]="false"
          [infiniteScrollDisabled]="isScrollDisable">
          <div class="pr-2" *ngIf="searchFamily?.length">
            <div *ngFor="let data of searchFamily; let i = index" (click)="setSelectedFamily(data, i)"
              [ngClass]="{ 'border-blue': i == selectedIndex, border: i != selectedIndex }"
              class="d-flex cursor-pointer justify-content-between border-radius-4 mt-2 p-2 overflow-hidden">
              <div class="d-flex align-items-center w-75">
                <img class="pb-1 pr-1" width="60" height="60" src="{{ data?.icons }}" />
                <div class="pl-2">
                  <div class="wrap-width font-size-14 text-capitalize font-weight-500 text-break">{{
                    data.name }}</div>
                  <div class="wrap-width font-size-14 font-size-md-12 w-100 text-capitalize font-weight-400 text-break">
                    {{
                    data.sub }}</div>
                </div>
              </div>
              <div class="px-2 pt-2 col-4 px-0">
                <div class="text-truncate font-size-11 font-size-md-9">{{ data.days }}</div>
                <div *ngIf="data.verified" class="pt-2 float-right">
                  <img src="assets/images/approved-success.svg" />
                </div>
                <div *ngIf="data.error" class="pt-2 float-right">
                  <img src="assets/images/review-error.svg" />
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 h-100 d-flex align-items-center justify-content-center" *ngIf="!searchFamily?.length">
            <span class="text-muted">{{ 'REPORTS.NO_RECORDS_FOUND' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="right-container px-3 py-4 col-lg-8 col-md-7 col-sm-12 col-12">
        <div class="border-bottom" *ngIf="selectedFamily">
          <div class="d-flex justify-content-between pb-3 align-items-center mr-4 col-12 px-0">
            <div class="d-flex align-items-center col-7 px-0">
              <img src="{{ selectedFamily?.icons }}" width="50" height="50" />
              <div class="pl-3 text-ellipsis">
                <div class="font-size-20  font-size-md-16 text-capitalize font-weight-500 text-ellipsis">
                  {{ selectedFamily?.name }}
                </div>
                <div class="text-capitalize text-ellipsis font-size-md-14">{{ selectedFamily?.sub }}</div>
              </div>
            </div>
            <div class="text-right">
              <button (click)="openVerticallyCentered(content)"
                class="btn btn-outline-secondary mr-2 font-size-14 width-100">
                <img src="assets/images/tick-blue-icon.svg" class="pr-1" />
                {{ 'BUTTONS.REVIEW' | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="search-height overflow-auto pb-2 mr-2" *ngIf="formsFields?.length">
          <div class="field border-bottom pl-1 py-3 font-size-14 font-size-md-12" *ngFor="let field of formsFields">
            <div class="question  text-capitalize font-weight-700 ">{{ field.key }}</div>
            <div class="answer  text-capitalize">{{ field.value }}</div>
          </div>
        </div>
        <div class="w-100 h-100 d-flex align-items-center justify-content-center" *ngIf="!formsFields?.length">
          <span class="text-muted"> {{ 'REPORTS.NO_RECORD_SELECTED' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-width d-flex flex-column">
    <div class="modal-body">
      <div class="d-flex align-items-center">
        <div class="px-3 py-2 border-right-line cursor-pointer" (click)="infoError()">
          <div class="text-center"><img src="assets/images/info-error.svg" /></div>
          <div class="text-center font-size-14 mt-2">{{ 'REPORTS.INFORMATION_HAS' | translate }}</div>
          <div class="text-center danger font-size-14 font-weight-500">{{ 'REPORTS.ERRORS' | translate }}</div>
        </div>
        <div class="px-3 py-2 cursor-pointer" (click)="infoCorrect()">
          <div class="text-center"><img src="assets/images/info-correct.svg" /></div>
          <div class="text-center font-size-14 mt-2">{{ 'REPORTS.INFORMATION_IS' | translate }}</div>
          <div class="text-center success font-size-14 font-weight-500">{{ 'REPORTS.CORRECT' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>