<section [style.background-color]="sideBar ? sideBar.header_side_bar : 'white'"
  class="side-navbar overflow-auto position-fixed pt-3 d-flex justify-content-between flex-column text-center">
  <div>
    <div class="p-3" *ngFor="let menu of navbarMenus">
      <a routerLink="{{ menu.tabLink }}" routerLinkActive #rla="routerLinkActive">
        <span [style.color]="rla.isActive ? sideBar?.icons_tabs?.secondary : sideBar?.icons_tabs?.primary"
          class="material-icons">
          {{ menu.tabIcon }}
        </span>
      </a>
    </div>
  </div>
  <div>
    <div class="p-3" *ngFor="let settings of navbarSettings">
      <a routerLink="{{ settings.tabLink }}" routerLinkActive #rla="routerLinkActive">
        <span [style.color]="rla.isActive ? sideBar?.icons_tabs?.secondary : sideBar?.icons_tabs?.primary"
          class="material-icons">
          {{ settings.tabIcon }}
        </span>
      </a>
    </div>
  </div>
</section>