import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IRuleEditorConfig, IRuleEditorValue } from 'src/app/services/json-rule-editor/json-rule-editor.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-json-rule-editor-modal',
  templateUrl: './json-rule-editor-modal.component.html',
  styleUrls: ['./json-rule-editor-modal.component.scss'],
})
export class JsonRuleEditorModalComponent implements OnInit, AfterViewInit {
  @Input() jsonRuleEditorConfig: IRuleEditorConfig = {
    jsonRuleSet: [],
    xmlFormId: '',
  };
  @Output() saveRuleEditorValue = new EventEmitter();
  iframe: any;
  iWindow: any;
  editorUrl = environment.jsonRuleEditor.editorPath;
  editorOrigin = environment.jsonRuleEditor.editorOrigin;
  iframeUrl: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) {
    this.iframeUrl = '';
  }

  @HostListener('window:message', ['$event'])
  onMessage(e: any): any {
    if (e.origin !== this.editorOrigin) {
      return false;
    }
    if (e.data && e.data.name) {
      const value: IRuleEditorValue = {
        xmlFormId: this.jsonRuleEditorConfig.xmlFormId,
        context: e.data,
      };
      this.saveRuleEditorValue.emit(value);
    }
  }

  ngOnInit() {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.editorOrigin + this.editorUrl);
  }

  ngAfterViewInit(): void {
    if (this.jsonRuleEditorConfig.jsonRuleSet.length) {
      this.iframe = document.getElementById('jsonRuleEditorIframe');
      this.iframe.addEventListener('load', () => {
        this.iWindow = this.iframe.contentWindow;
        this.postMsg();
      });
    }
  }

  postMsg() {
    this.iWindow.postMessage(this.jsonRuleEditorConfig.jsonRuleSet, '*');
  }
}
