import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, map, filter, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';

import { StorageService } from '../../storage/storage.service';
import { LoaderService } from '../../loader/loader.service';
import { AppService } from '../../app.service';

const SJWT = 'SJWT';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  private isRefreshToken = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private storageService: StorageService,
    private loaderService: LoaderService,
    private appService: AppService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenData = this.storageService.get('token');
    const refreshTokenData = this.storageService.get('refreshToken');
    const token = `${SJWT} ${tokenData}`;
    const refreshToken = `${SJWT} ${refreshTokenData}`;
    this.totalRequests++;
    this.loaderService.showLoading();
    const modifiedReq = request.clone({
      headers: request.headers.set('Authorization', tokenData !== '' ? token : refreshToken),
    });
    return next.handle(modifiedReq).pipe(
      map((event: any) => event),
      catchError((err: HttpErrorResponse, caught) => {
        if (err.status === 401) {
          if (!this.isRefreshToken) {
            this.isRefreshToken = true;
            this.refreshTokenSubject.next(null);
            this.storageService.set('token', '');
            if (request.url !== '/auth/login' && request.url !== '/auth/forgotPassword') {
              this.appService.refreshToken().subscribe((data) => {
                this.isRefreshToken = false;
                this.storageService.set('refreshToken', data.refreshToken);
                this.storageService.set('token', data.token);
                this.refreshTokenSubject.next(data.token);
                return next.handle(this.addToken(request, token));
              });
            } else {
              this.isRefreshToken = false;
            }
          } else {
            return this.refreshTokenSubject.pipe(
              filter((token) => token != null),
              take(1),
              switchMap((tokenData) => {
                return next.handle(this.addToken(request, tokenData));
              })
            );
          }
        }
        return observableThrowError(err);
      }),
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.loaderService.hideLoading();
        }
      })
    );
  }
  addToken(request: HttpRequest<any>, token: any) {
    return request.clone({
      setHeaders: {
        Authorization: `${SJWT} ${token}`,
      },
    });
  }
}
