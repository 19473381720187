import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { base64Prefix } from 'src/app/constants/constants';
import { PeopleService } from 'src/app/services/people/people.service';

import appRouter from '../../../../constants/api-route-constants';

@Component({
  selector: 'app-household-details',
  templateUrl: './household-details.component.html',
  styleUrls: ['./household-details.component.scss'],
})
export class HouseholdDetailsComponent implements OnInit {
  active = 1;
  members: any = [];
  branch: any = {};
  area: any = {};
  family: any = {};
  familyIcon: any;
  resources: any = {};

  constructor(private router: Router, private route: ActivatedRoute, private peopleService: PeopleService) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.getResources();
    this.getHouseholdDetails(id);
    this.updateParentValues();
  }

  /**
   * <p>
   *  Route back to the Chw Area Detail given area details
   * </p>
   *
   * @param area
   */
  backToChwDetail(area: any): void {
    const id = area._id;
    this.router.navigate([appRouter.home.peopleMenu.chwDetail, id]);
  }

  /**
   * <p>
   *  Route to the Supervisor Detail given person details
   * </p>
   *
   * @param member
   */
  routeToSupervisorDetail(member: any): void {
    const id = member._id;
    this.router.navigate([appRouter.home.peopleMenu.supervisorDetail, id]);
  }

  /**
   * <p>
   *   Get the updated parent values like branch, area and
   * family for the supervisor/person.
   * </p>
   */
  private updateParentValues() {
    this.peopleService.branchUpdated.subscribe((data) => {
      this.branch = data;
    });

    this.peopleService.areaUpdated.subscribe((data) => {
      this.area = data;
    });

    this.peopleService.familyUpdated.subscribe((data) => {
      this.family = data;
    });
  }

  /**
   * <p>
   *  Get the household details by the household id
   * </p>
   *
   * @param id - household id to get the members of household
   */
  private getHouseholdDetails(id: string | null) {
    const startKey = '["' + id + '"]';
    const endKey = '["' + id + '", {}]';

    this.peopleService
      .getDocDetailsByParentId(encodeURIComponent(startKey), encodeURIComponent(endKey))
      .subscribe((res) => {
        res.rows.forEach((row: any) => {
          if (row.doc.type === 'person') {
            this.members.push(row.doc);
          }
        });
      });
  }

  /**
   * <p>
   *   Get Icons from the resources.
   * </p>
   */
  private getResources() {
    this.peopleService.getResources().subscribe((res: any) => {
      this.resources = res.resources;
      this.peopleService.getIcon(this.resources.clinic).subscribe((image: any) => {
        if (image) {
          this.familyIcon = base64Prefix + image.base64;
        }
      });
    });
  }
}
