import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { SideNavbarComponent } from './side-navbar/side-navbar.component';
import { HeaderComponent } from './header/header.component';
import { MessagesComponent } from './messages/messages.component';
import { PeopleModule } from './people/people.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LocationComponent } from './location/location.component';
import { ReportsComponent } from './reports/reports.component';
import { SharedModule } from '../shared/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    DashboardComponent,
    SideNavbarComponent,
    HeaderComponent,
    MessagesComponent,
    LocationComponent,
    ReportsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    GoogleMapsModule,
    DashboardRoutingModule,
    AutocompleteLibModule,
    NgbModule,
    PeopleModule,
    TranslateModule,
    ReactiveFormsModule,
    SharedModule,
    InfiniteScrollModule
  ],
})
export class DashboardModule {}
