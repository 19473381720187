<ng-template #baseModalContent let-modal>
  <div class="modal-content border-0" [ngClass]="true ? modalStyle : ''">
    <div class="modal-header border-0">
      <h4 class="modal-title modal-header-label font-weight-500 font-size-24" id="modal-basic-title ml-1">
        {{ title }}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"><img src="assets/images/cross.svg" alt="close" width="15" /></span>
      </button>
    </div>
    <ng-container *ngIf="content" [ngTemplateOutlet]="content"></ng-container>
    <div class="modal-footer border-0">
      <button type="button" class="btn btn-outline-secondary font-size-14" (click)="modal.dismiss('Cancel click')">
        {{ 'BUTTONS.CANCEL' | translate }}
      </button>
      <button type="button" [disabled]="!isFormValid || isAssociatedContact" class="btn btn-secondary font-size-14"
        (click)="modal.close('Save click')">
        {{ 'BUTTONS.SUBMIT' | translate }}
      </button>
    </div>
  </div>
</ng-template>